import cookie_js from 'cookie_js';
import Cookies from 'js-cookie'
import {is_local_build} from "../index";

let test_user_key = '7d8536d9abcffa7819a7f6fcc8ffcd6721d6dccc';

export const isAuthGuard = (to, from, next) => {
    let userKey = is_local_build ?  test_user_key : cookie_js.get('user_key');

    if (to.meta.auth) {
      // Cookies.set('', '', { domain: 'fluidsens.embedded.biz.ua' })
      // Cookies.get('user_key')
      if (userKey) {
        next();
        return;
      }
      next.redirect('https://fluidsens.embedded.biz.ua/');
      window.location.href = 'https://fluidsens.embedded.biz.ua/';
    } else {
      next();
    }
};
  // "homepage": "",



