import React, {useEffect, useCallback, useState, useRef} from "react";
import { Typography } from "@material-ui/core";
import FileBase64 from 'react-file-base64';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { isPushNotificationSupported } from "../../pushNotifications";
import Paper from "@material-ui/core/Paper";
import validator from "validator";
import { makeStyles } from "@material-ui/core/styles";
import usePushNotifications from "../../usePushNotifications";
import TextField from "@material-ui/core/TextField";
import { useInput } from "./../hooks/useInput";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Cookies from "js-cookie";
import cookie_js from "cookie_js";
import * as actions from "../../redux/actions/deviceActions/device.action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TimezoneSelect from "react-timezone-select";
import { ToastProvider, useToasts } from "react-toast-notifications";
import {isMobilePhone} from 'validator'
import {isValidEmail, isValidPhone} from "../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  block: {
    width: "100%",
    marginTop: 2,
    backgroundColor: "#fff",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
  },
  //   paper: {
  //     marginRight: theme.spacing(2),
  //   },

  menuItemBlock: {
    maxWidth: 198,
  },

  btnMenu: {
    width: 198,
    borderColor: "#2E5BFF",
    borderWidth: 2,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
  },
  textField: {
    width: 142,
    height: 26,
    padding: '4px 6px',
    marginLeft: 12,
    borderColor: "#EAF0F5",
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 5,
    borderRadius: 5,
    outline: 'none'
  },

  formStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  crumbsTextStyle: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  error_text: {
    // position: "absolute",
    // bottom: -5,
    // left: 0,
    marginTop: 5,
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: "normal",
    color: '#ff2e2e',
    marginLeft: 10,
  }
}));

const ErrorText = (props) => {
  const classes = useStyles();
  return <div className={classes.error_text}>
      <span>{props.text_error}</span>
  </div>
}

const ReceiveAlertInput = (props) => {
  const [state, set_state] = React.useState(props.def_val);
  const classes = useStyles();
  const static_state =  useRef(props.def_val);

  useEffect(() => {
    if (static_state.current!==props.def_val) {
      set_state(props.def_val)
      static_state.current = props.def_val;
    }
  }, [props.def_val]);

  return       <>        <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: props.marginTop ? props.marginTop : 0,
        justifyContent: "space-between",
      }}
  >
    <form
        style={{
          display: "flex",
          flexDirection: "column",
          position: 'relative'
        }}
    >
      <label>
        <input
            className={classes.textField}
            style={{ width: 130 }}
            placeholder={props.placeholder}
            type={props.type ? props.type : 'text'}
            value={state}
            onChange={(e)=>{
              let new_value = e.target.value;
              static_state.current = new_value;
              set_state(new_value)
              props.on_change(new_value, e.target.name)
            }}
            name={props.name}
        />
      </label>

    </form>
    <Button
        style={{
          backgroundColor: "#00C99B",
          color: "#fff",
          marginTop: 5,
          height: 26,
          width: 90,
          marginRight: 10,
          fontSize: 11,
          // marginLeft: 10 ,
          opacity: props.error ? 0.2 : 1,
          textTransform: "none",
        }}
        disabled={props.error ? true : false}
        variant="contained"
        onClick={() => props.handle_add(props.name)}
        margin="normal"
    >
      <span style={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}>
            {props.add_btn_text}
      </span>

    </Button>
  </div>
    {
    props.error ?
        <ErrorText text_error={props.error}/> : null
  }
  </>
}

const YourAccComponent = (props) => {
  const dispatch = useDispatch();
  const [selectedTimezone, setSelectedTimezone] = React.useState(
    JSON.parse(localStorage.getItem("timeZoneValue")) ?? {}
  );












  const [checkedPush, setCheckedPush] = React.useState(false);
  const userInfo = useSelector((state) => state.deviceReducer.currentUserInfo);
  const phonesAndMails = useSelector(
    (state) => state.deviceReducer.phonesAndMails
  );
  const addDev = useCallback(
    (name, devId) => dispatch(actions.addDev(name, devId)),
    [dispatch]
  );

  const { addToast } = useToasts();


  const deviceInfoArr = useSelector(
    (state) => state.deviceReducer.currDeviceArr
  );

  const setNewArrWithTimeZone = useCallback(
    (arr) => dispatch(actions.setCurrentDeviceArr(arr)),
    [dispatch]
  );

  const selectTimeZone = (value) => {
    ;
    localStorage.setItem("timeZone", value.value);
    localStorage.setItem("timeZoneValue", JSON.stringify(value));
    if (deviceInfoArr) {
      let newArr = deviceInfoArr.map((item) => {
        return {
          ...item,
          date_cr: moment(item.date_cr).tz(value.value).format(),
        };
      });
      setNewArrWithTimeZone(newArr);
    }
    setSelectedTimezone(value);
  };
  const connect = (evt) => {
    if (addDeviceID.length < 8 || addDeviceID.length > 40) {
      return;
    }
    if (!addDeviceName || addDeviceID.length > 40) {
      return;
    }
    addDev(addDeviceName, addDeviceID);
    props.handleClose(evt);
  };

  const getPhonesAndMails = useCallback(
    () => dispatch(actions.getPhonesAndMails()),
    [dispatch]
  );

  const addPhone = useCallback((phone) => dispatch(actions.addPhone(phone)), [
    dispatch,
  ]);

  const addMail = useCallback((mail) => dispatch(actions.addMail(mail)), [
    dispatch,
  ]);

  const deleteDev = useCallback((dev) => dispatch(actions.deleteDev(dev)), [
    dispatch,
  ]);


  const removeAlert = useCallback((id) => dispatch(actions.removeAlert(id)), [
    dispatch,
  ]);

  useEffect(() => {
    getPhonesAndMails();
  }, []);

  const {
    value: loginName,
    bind: bindLoginName,
    reset: resetLoginName,
  } = useInput(userInfo?.login ? userInfo.login : "");
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput(
    userInfo?.mail ?? ""
  );
  const {
    value: notEmail,
    bind: bindNotEmail,
    reset: resetNotEmail,
  } = useInput("");
  const { value: phone, bind: bindPhone, reset: resetPhone } = useInput("");
  const { value: myName, bind: bindMyName, reset: resetMyName } = useInput(
    userInfo?.name === "none"  || !userInfo?.hasOwnProperty('name') || !userInfo?.name ? "" : userInfo.name
  );
  const {
    value: addDeviceID,
    bind: bindAddDeviceID,
    reset: resetAddDeviceID,
  } = useInput("");
  const {
    value: addDeviceName,
    bind: bindAddDeviceName,
    reset: resetAddDeviceName,
  } = useInput("");



  const [openAddDeviceDialog, setOpenAddDeviceDialog] = React.useState(false);
  const [openAddMailDialog, setOpenAddMailDialog] = React.useState(false);

  const classes = useStyles();
  const [accDetailOpen, setAccDetailOpen] = React.useState(false);
  const [notifMethodOpen, setNotifMethodOpen] = React.useState(false);
  const [othersOpen, setOthersOpen] = React.useState(false);
  const [adminAccDetailOpen, setAdminAccDetailOpen] = React.useState(false);
  const [removeDeviceListOpen, setRemoveDeviceListOpen] = React.useState(false);
  const [openRemoveDeviceDialog, setOpenRemoveDeviceDialog] = React.useState(
    false
  );

  const currentDevice = useSelector(
    (state) => state.deviceReducer.currentDevice
  );

  const handleCloseMailDialor = () => {
    setOpenAddMailDialog(false);
  };
  const setMultilineMode = useCallback(
    (multilineMode) => dispatch(actions.setMultilineMode(multilineMode)),
    [dispatch]
  );
  const multilineMode = useSelector(
    (state) => state.deviceReducer.multilineMode
  );

  const handleClickOpen = () => {

    if(openAddDeviceDialog){
      setOpenAddDeviceDialog(false);

      return;
    };

    if(multilineMode){
      setMultilineMode(false);
    }
    if(currentDevice) {
      setDevOnDel(currentDevice);
    }


    setOpenAddDeviceDialog(true);
  };

  useEffect(()=>{
    setDeleteDevMode(openAddDeviceDialog);
  }, [openAddDeviceDialog]);

  const handleClose = () => {

    setOpenAddDeviceDialog(false);
  };
  const setDevOnDel = useCallback(
    (dev) => dispatch(actions.setDeleteDevice(dev)),
    [dispatch]
  );

  const setDeleteDevMode = useCallback(
    (status) => dispatch(actions.setDeleteDevMode(status)),
    [dispatch]
  );

  const devOnDel = useSelector(
    (state) => state.deviceReducer.devOnDel
  );

  const delete_dev_mode = useSelector(
    (state) => state.deviceReducer.delete_dev_mode
  );







  //receive alert
  const [receiveAlertInputErrors, set_receiveAlertInputErrors] = useState({
    alert_email: '',
    alert_phone: ''
  })
  const [alert_email, set_alert_email] = useState('')
  const [alert_phone, set_alert_phone] = useState('')


  const on_change_receive_alert_input = useCallback(
      (new_value, name) => {
        switch (name) {
          case 'alert_email': {
            if (!isValidEmail(new_value)) {
              set_receiveAlertInputErrors((prev_state)=>({...prev_state, [name]: 'Please enter a valid email'}))
            } else {
              set_receiveAlertInputErrors((prev_state)=>({...prev_state, [name]: ''}))
            }
            set_alert_email(new_value)
            break
            return
          }
          case 'alert_phone': {
            // console.log('alert_phone -', new_value)

            set_alert_phone(new_value)
            break
            return
          }
        }
      },
      [],
  );


  const on_adding_receiver_alert = useCallback((name) => {
    switch (name) {
      case 'alert_email': {
        if (receiveAlertInputErrors['alert_email']) {
          return;
        }
        if (phonesAndMails?.mails?.find((item) => item.value === alert_email)) {
          addToast("This email is already attached", {
            appearance: "error",
            autoDismissTimeout: 2000,
            autoDismiss: true,
          });
          return;
        }
        addMail(alert_email);
        set_alert_email('');
        break
        return
      }
      case 'alert_phone': {
        if (receiveAlertInputErrors['alert_phone']) {
          return;
        }
        if (phonesAndMails?.phones?.find((item) => item.value + "" === alert_phone)) {
          addToast("This phone is already attached", {
            appearance: "error",
            autoDismissTimeout: 2000,
            autoDismiss: true,
          });
          return;
        }
        addPhone(alert_phone);
        set_alert_phone('')

        break
        return
      }
    }
  }, [alert_email, alert_phone, phonesAndMails, receiveAlertInputErrors])


  return (
    <ClickAwayListener onClickAway={delete_dev_mode? (e)=>props.handleClose(e, 'do') : (e)=> props.handleClose(e)}>
      <div
        style={{
          width: 260,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 6,
          backgroundColor: "#F2F2F2",
          borderRadius: 15,
          boxShadow: "-3px 3px 10px 4px rgba(221, 221, 221, 1)",
          marginTop: 5,
        }}
      >
        <Paper
          variant="outlined"
          className={classes.block}
          onClick={() => setAccDetailOpen(!accDetailOpen)}
          style={
            accDetailOpen
              ? { borderColor: "#FF2E2E", borderWidth: 2, marginTop: 0 }
              : { borderColor: "rgba(0, 0, 0, 0.12)", marginTop: 0 }
          }
        >
          <Typography
            style={{ fontSize: 14, fontFamily: "Roboto", fontWeight: "bold" }}
          >
            Users Account Details{" "}
          </Typography>
          {accDetailOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Paper>

        <Paper
          className={classes.block}
          style={!accDetailOpen ? { display: "none" } : { display: "flex" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <label>
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                  }}
                >
                  My Name:
                </span>
                <input
                  className={classes.textField}
                  type="text"
                  value={myName}
                  {...bindMyName}
                  disabled={true}
                  name="myName"
                />
              </label>

              <label>
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                  }}
                >
                  Login Name:
                </span>
                <input
                  className={classes.textField}
                  type="text"
                  value={myName}
                  {...bindLoginName}
                  disabled={true}
                  name="myName"
                />
              </label>

              <label>
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                  }}
                >
                  Email:
                </span>
                <input
                  className={classes.textField}
                  type="text"
                  value={myName}
                  disabled={true}
                  {...bindEmail}
                  name="myName"
                />
              </label>

              {/* <label>
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                  }}
                >
                  Phone:
                </span>
                <input
                  className={classes.textField}
                  type="text"
                  value={myName}
                  {...bindPhone}
                  name="myName"
                />
              </label> */}
            </form>

            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#00C99B",
                  color: "#fff",
                  marginTop: 15,
                  height: 30,
                  width: 110,
                }}
                variant="contained"
                onClick={() => setAccDetailOpen(false)}
                margin="normal"
              >
                Confirm
              </Button>
              <Button
                style={{
                  backgroundColor: "#B0BAC9",
                  color: "#fff",
                  marginTop: 15,
                  height: 30,
                  width: 110,
                }}
                variant="contained"
                onClick={() => setAccDetailOpen(false)}
              >
                Close
              </Button>
            </p>
          </div>
        </Paper>
        <Paper
          onClick={() => setNotifMethodOpen(!notifMethodOpen)}
          variant="outlined"
          style={
            notifMethodOpen
              ? { borderColor: "#FF2E2E", borderWidth: 2 }
              : { borderColor: "rgba(0, 0, 0, 0.12)" }
          }
          className={classes.block}
        >
          <Typography
            style={{ fontSize: 14, fontFamily: "Roboto", fontWeight: "bold" }}
          >
            Add Users to Receive Alert
          </Typography>
          {notifMethodOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Paper>
        <Paper
          style={
            notifMethodOpen
              ? { display: "block", marginTop: 5 }
              : { display: "none" }
          }
          className={classes.block}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 12,
              marginLeft: 10,
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            {/* <Typography style={{fontSize: 14}}>Phones: </Typography> */}
            {phonesAndMails?.phones
              ? phonesAndMails.phones.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      fontSize: 12,
                      marginLeft: 10,
                      marginRight: 10,
                      marginBottom: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography style={{ fontSize: 14 }}>
                      {item.value}
                    </Typography>
                    <Button
                      style={{
                        backgroundColor: "#ff2e2e",
                        color: "#fff",
                        width: 20,
                        height: 20,
                        borderRadius: 50,
                        // fontSize: 9,
                        minWidth: 10,
                        padding: 0,
                        alignItems: "center",
                      }}
                      variant="contained"
                      margin="normal"
                      onClick={() => removeAlert(item.id)}
                    >
                      —
                    </Button>
                  </div>
                ))
              : null}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 12,
              marginLeft: 10,
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            {/* <Typography style={{fontSize: 14}}>Emails: </Typography> */}
            {phonesAndMails?.mails
              ? phonesAndMails.mails.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      fontSize: 12,
                      marginLeft: 10,
                      marginRight: 10,
                      marginBottom: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography style={{ fontSize: 14 }}>
                      {item.value}
                    </Typography>
                    <Button
                      style={{
                        backgroundColor: "#ff2e2e",
                        color: "#fff",
                        width: 20,
                        height: 20,
                        borderRadius: 50,
                        alignItems: "center",
                        minWidth: 10,
                        padding: 0,
                      }}
                      variant="contained"
                      margin="normal"
                      onClick={() => removeAlert(item.id)}
                    >
                      —
                    </Button>
                  </div>
                ))
              : null}
          </div>


          <ReceiveAlertInput
              def_val={alert_email}
              name={'alert_email'}
              placeholder={'Enter email'}
              error={receiveAlertInputErrors['alert_email']}
              add_btn_text={'Add Email'}
              on_change={on_change_receive_alert_input}
              handle_add={on_adding_receiver_alert}
          />
          <ReceiveAlertInput
              marginTop={10}
              def_val={alert_phone}
              name={'alert_phone'}
              placeholder={'Enter phone number'}
              type={'number'}
              error={receiveAlertInputErrors['alert_phone']}
              add_btn_text={'Add Phone'}
              on_change={on_change_receive_alert_input}
              handle_add={on_adding_receiver_alert}
          />

        </Paper>

        {/* <Dialog
          open={openAddPhoneDialog}
          onClose={setOpenAddPhoneDialog(!openAddPhoneDialog)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "#0F34BB", textAlign: "center" }}
          >
            {"Add Phone"}
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <label>
                  <span
                    style={{
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                    }}
                  >
                    Enter Device ID:
                  </span>
                  <input
                    className={classes.textField}
                    type="text"
                    {...bindPhone}
                    name="addDeviceID"
                  />
                </label>
              </form>

              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#00C99B",
                    color: "#fff",
                    marginTop: 15,
                    height: 30,
                    width: 110,
                  }}
                  variant="contained"
                  onClick={(evt) => connect(evt)}
                  margin="normal"
                >
                  Add Phone
                </Button>
              </p>
            </div>
          </DialogContent>
        </Dialog> */}
        {/*
        <Dialog
          open={openAddMailDialog}
          onClose={handleCloseMailDialor}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "#0F34BB", textAlign: "center" }}
          >
            {"Add Email"}
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <label>
                  <span
                    style={{
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                    }}
                  >
                    Enter Email:
                  </span>
                  <input
                    className={classes.textField}
                    type="text"
                    {...bindEmail}
                    name="addDeviceID"
                  />
                </label>
              </form>

              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#00C99B",
                    color: "#fff",
                    marginTop: 15,
                    height: 30,
                    width: 110,
                  }}
                  variant="contained"
                  onClick={() => addMail()}
                  margin="normal"
                >
                  Add Email
                </Button>
              </p>
            </div>
          </DialogContent>
        </Dialog> */}

        <Paper
          className={classes.block}
          onClick={()=>handleClickOpen()}
          variant="outlined"
          style={
            openAddDeviceDialog
              ? { borderColor: "#FF2E2E", borderWidth: 2 }
              : { borderColor: "rgba(0, 0, 0, 0.12)" }
          }
        >
          <Typography
            style={{ fontSize: 14, fontFamily: "Roboto", fontWeight: "bold" }}
          >
            Remove Device
          </Typography>
          {openAddDeviceDialog ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Paper>

        <Paper
          className={classes.block}
          style={!openAddDeviceDialog ? { display: "none" } : { display: "flex",  }}
        >
            <p
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#FF2E2E",
                  color: "#fff",

                  height: 30,
                  width: 110,
                }}
                variant="contained"
                onClick={() => {deleteDev(devOnDel?.device_key); setOpenAddDeviceDialog(false);}}
                margin="normal"
              >
                Delete
              </Button>
              <Button
                style={{
                  backgroundColor: "#B0BAC9",
                  color: "#fff",
                  height: 30,
                  width: 110,
                }}
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </p>

        </Paper>

        {/* <Dialog
          open={openAddDeviceDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "#0F34BB", textAlign: "center" }}
          >
            {"Add Device"}
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <label>
                  <span
                    style={{
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                    }}
                  >
                    Enter Device ID:
                  </span>
                  <input
                    className={classes.textField}
                    type="text"
                    {...bindAddDeviceID}
                    name="addDeviceID"
                  />
                </label>

                <label>
                  <span
                    style={{
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                    }}
                  >
                    Enter Device Name:
                  </span>
                  <input
                    className={classes.textField}
                    type="text"
                    {...bindAddDeviceName}
                    name="addDeviceName"
                  />
                </label>
              </form>

              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#00C99B",
                    color: "#fff",
                    marginTop: 15,
                    height: 30,
                    width: 110,
                  }}
                  variant="contained"
                  onClick={(evt) => connect(evt)}
                  margin="normal"
                >
                  Connect
                </Button>
                <Button
                  style={{
                    backgroundColor: "#B0BAC9",
                    color: "#fff",
                    marginTop: 15,
                    height: 30,
                    width: 110,
                  }}
                  variant="contained"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </p>
            </div>
          </DialogContent>
        </Dialog> */}

        {/* <Paper
          className={classes.block}
          onClick={() => setRemoveDeviceListOpen(!removeDeviceListOpen)}
          variant="outlined"
          style={
            removeDeviceListOpen
              ? { borderColor: "#FF2E2E", borderWidth: 2 }
              : { borderColor: "rgba(0, 0, 0, 0.12)" }
          }
        >
          <Typography
            style={{ fontSize: 14, fontFamily: "Roboto", fontWeight: "bold" }}
          >
            Remove Device         {(removeDeviceListOpen  ? (
          <ArrowDropUpIcon />
        ) : (
          <ArrowDropDownIcon />
        ))}
          </Typography>
        </Paper>

        <Paper
          className={classes.block}
          //   onClick={handleClickOpen}
          style={
            !removeDeviceListOpen ? { display: "none" } : { display: "flex" }
          }
        >
          <Typography
            style={{
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "bold",
              color: "red",
            }}
          >
            Click on Device To Select
          </Typography>
        </Paper>

        {removeDeviceListOpen
          ? [1, 2].map((item) => (
              <Paper
                className={classes.block}
                style={{ paddingTop: 5, paddingBottom: 5, display: "block" }}
                onClick={() => setOpenRemoveDeviceDialog(true)}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    marginLeft: 15,
                  }}
                >
                  abracadabra
                </Typography>
              </Paper>
            ))
          : null}

        <Dialog
          open={openRemoveDeviceDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "#FF2E2E", textAlign: "center" }}
          >
            {"Unpin Device"}
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <label>
                  <span
                    style={{
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                    }}
                  >
                    Enter Device ID:
                  </span>
                  <input
                    className={classes.textField}
                    type="text"
                    value={removeDeviceID}
                    {...bindRemoveDeviceID}
                    name="removeDeviceID"
                  />
                </label>

                <label>
                  <span
                    style={{
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                    }}
                  >
                    Enter Device Name:
                  </span>
                  <input
                    className={classes.textField}
                    type="text"
                    value={removeDeviceName}
                    {...bindRemoveDeviceName}
                    name="removeDeviceName"
                  />
                </label>
              </form>
              <p
                style={{
                  color: "#2E5BFF",
                  maxWidth: 285,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Are you sure you want to disconnect the above device from your
                list?
              </p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#FF2E2E",
                    color: "#fff",
                    marginTop: 15,
                    height: 30,
                    width: 110,
                  }}
                  variant="contained"
                  onClick={() => console.log("ghS")}
                  margin="normal"
                >
                  YES
                </Button>
                <Button
                  style={{
                    backgroundColor: "#00C99B",
                    color: "#fff",
                    marginTop: 15,
                    height: 30,
                    width: 110,
                  }}
                  variant="contained"
                  onClick={() => setOpenRemoveDeviceDialog(false)}
                >
                  NO
                </Button>
              </p>
            </div>
          </DialogContent>
        </Dialog>

        <Paper
          variant="outlined"
          className={classes.block}
          onClick={() => setAdminAccDetailOpen(!adminAccDetailOpen)}
          style={
            adminAccDetailOpen
              ? { borderColor: "#FF2E2E", borderWidth: 2 }
              : { borderColor: "rgba(0, 0, 0, 0.12)" }
          }
        >
          <Typography
            style={{ fontSize: 14, fontFamily: "Roboto", fontWeight: "bold" }}
          >
            Add Sub Admin         {(adminAccDetailOpen  ? (
          <ArrowDropUpIcon />
        ) : (
          <ArrowDropDownIcon />
        ))}
          </Typography>
        </Paper>

        <Paper
          className={classes.block}
          style={!adminAccDetailOpen ? { display: "none" } : { display: "flex" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <label>
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                  }}
                >
                  User Name:
                </span>
                <input
                  className={classes.textField}
                  type="text"
                  value={adminUserName}
                  {...bindAdminUserName}
                  name="adminUserName"
                />
              </label>

              <label>
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                  }}
                >
                  Admin Address:
                </span>
                <input
                  className={classes.textField}
                  type="text"
                  value={adminAddress}
                  {...bindAdminAddress}
                  name="adminAddress"
                />
              </label>

              <label>
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                  }}
                >
                  Email:
                </span>
                <input
                  className={classes.textField}
                  type="text"
                  value={adminEmail}
                  {...bindAdminEmail}
                  name="adminEmail"
                />
              </label>

              <label>
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                  }}
                >
                  Phone:
                </span>
                <input
                  className={classes.textField}
                  type="text"
                  value={adminPhone}
                  {...bindAdminPhone}
                  name="adminPhone"
                />
              </label>
            </form>
            <Button
                style={{
                  backgroundColor: "#2E5BFF",
                  color: "#fff",
                  marginTop: 10,
                  height: 30,
                  width: '100%',
                }}
                variant="contained"
                onClick={() => console.log("ghS")}
                margin="normal"
              >
                Add Another
              </Button>
            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >


              <Button
                style={{
                  backgroundColor: "#00C99B",
                  color: "#fff",
                  height: 30,
                  width: 110,
                }}
                variant="contained"
                onClick={() => console.log("ghS")}
                margin="normal"
              >
                Save
              </Button>
              <Button
                style={{
                  backgroundColor: "#B0BAC9",
                  color: "#fff",
                  height: 30,
                  width: 110,
                }}
                variant="contained"
                onClick={() => setAccDetailOpen(false)}
              >
                Close
              </Button>
            </p>
          </div>
        </Paper>
 */}

        <Paper
          onClick={() => setOthersOpen(!othersOpen)}
          variant="outlined"
          style={
            othersOpen
              ? { borderColor: "#FF2E2E", borderWidth: 2 }
              : { borderColor: "rgba(0, 0, 0, 0.12)" }
          }
          className={classes.block}
        >
          <Typography
            style={{ fontSize: 14, fontFamily: "Roboto", fontWeight: "bold" }}
          >
            Others
          </Typography>
          {othersOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Paper>
        <Paper
          style={othersOpen ? { display: "block" } : { display: "none" }}
          className={classes.block}
        >
          {/* {isPushNotificationSupported() ? (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={!checkedPush}
                  onChange={() => setCheckedPush(!checkedPush)}
                />
              }
              label="Show Badge"
            />
          ) : null} */}

          <div
            style={{
              marginLeft: 10,
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "Roboto",
                fontWeight: "normal",
              }}
            >
              Timezone:{" "}
            </Typography>

            <div style={{ width: 140 }}>
              <TimezoneSelect
                width={140}
                value={selectedTimezone}
                onChange={(tz) => selectTimeZone(tz)}
                size="small"
              />
            </div>
          </div>

          {/* <Typography
            style={
              rep
                ? {
                    marginTop: 20,
                    fontSize: 14,
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    marginLeft: 22,
                    borderWidth: 2,
                    borderColor: "green",
                    // backgroundColor: "aqua",s
                    borderStyle: 'solid',
                    display: 'flex',
                    marginRight: 10,
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }
                : {
                  marginRight: 10,
                  justifyContent: 'space-between',
                    marginTop: 20,
                    fontSize: 14,
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    marginLeft: 22,
                    display: 'flex',
                    alignItems: 'center'
                  }
            }
            onClick={() => setRep(!rep)}
            variant="outlined"
          >
            Report {rep ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Typography>

          {rep ? (
            <form
              style={{ marginLeft: 22, marginTop: 10 }}
              enctype="multipart/form-data"
              // action={
              //   `${URL_API}/dev_api/sys_report_user.php?user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b`
              //   // ${cookie_js.get('user_key')}
              // }
              method="POST"
            >
              <input
                  placeholder='Theme report'
                  className={classes.textField}
                  style={{marginLeft: 0, marginBottom: 10}}
                  type="text"
                  value={themeRep}
                  {...bindThemeRep}
                  name="adminPhone"
                />
              <textarea name="text" {...bindTR} value={TR} style={{marginBottom: 10}}></textarea>

              <FileBase64
        multiple={ true }
        onDone={(file)=>setPR(file) } />


              <Button style={{marginTop:10}} onClick={()=>{

                            if(Number(PR[PR.length-1].size.slice(0, PR[PR.length-1].size.length-2))>8000){
                              return;
                            }
                              if(!PR[PR.length-1].type.includes('image')){return;}

                                  // let y =  PR[PR.length-1]===PR[PR.length-2] ? 2 : 1;
                                  // if((PR.length*(3/4))-y > 8000000){
                                  //   return;
                                  // }
                if(!themeRep){
                  return;
                }
                let userKey = cookie_js.get('user_key');
                fetch(`${URL_API}/dev_api/sys_report_user.php?user_key=${userKey}`, {
                  method: 'POST', // *GET, POST, PUT, DELETE, etc.
     // include, *same-origin, omit
                  headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                  },
                // manual, *follow, error
        // no-referrer, *client
                  body: JSON.stringify({theme: themeRep ,textReport: TR, image: PR}) // body data type must match "Content-Type" header
                }).then(()=>{
                  setRep(false);
                  resetThemeRep();
                  resetTR();
                  setPR('');
                });

              }}>Submit</Button>
            </form>
          ) : null} */}
        </Paper>

        <Paper
          variant="outlined"
          className={classes.block}
          onClick={() => {
            cookie_js.set(
              {
                user_key: "",
                pass_key: "",
                login_key: "",
              },
              {
                domain: ".fluidsens.embedded.biz.ua",
              }
            );
            // cookie_js.remove('user_key');
            // cookie_js.empty();
            window.location.href = "https://fluidsens.embedded.biz.ua/";
          }}
        >
          <Typography
            style={{ fontSize: 14, fontFamily: "Roboto", fontWeight: "bold" }}
          >
            Logout
          </Typography>
        </Paper>
      </div>
    </ClickAwayListener>
  );
};

export default YourAccComponent;
