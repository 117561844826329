import React, { useEffect, useCallback } from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/deviceActions/device.action";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },

  menuItemBlock: {
    maxWidth: 198,
  },
  textField: {
    width: 168,
    height: 26,
    padding: 0,
    borderColor: "#EAF0F5",
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 5,
  },

  block: {
    width: "100%",
    marginTop: 5,
    backgroundColor: "#fff",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },

  btnMenu: {
    width: 198,
    borderColor: "#2E5BFF",
    borderWidth: 2,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
  },
}));

const StartStopDeviceComponent = (props) => {
  const dispatch = useDispatch();
  const  startDevice = useCallback((devId) => dispatch(actions.startDev(devId)), [
    dispatch,
  ]);
  const startStopDevices = useSelector(
    (state) => state.deviceReducer.start_stop_devices
  );

  const startStopMode = useSelector(
    (state) => state.deviceReducer.start_stop_mode
  );

  const setStartStopDevices = useCallback(
    (device) => dispatch(actions.setStartStopDevices(device)),
    [dispatch]
  );
  const  stopDevice = useCallback((devId) => dispatch(actions.stopDev(devId)), [
    dispatch,
  ]);
  const setStartStopMode = useCallback(
    (stStMode) => dispatch(actions.setStartStopMode(stStMode)),
    [dispatch]
  );

  const start =(evt, devId)=> {

    props.sat(false);
    setStartStopMode(false);
    startDevice(devId);
    props.handleClose(evt, false)
  }


  const stop =(evt,devId)=> {
    props.sop(false);
    setStartStopMode(false);
    stopDevice(devId);
    props.handleClose(evt, false)
  }
  const currentDevice = useSelector(state => state.deviceReducer.currentDevice);
  const classes = useStyles();
  return (
    <div
      style={{
        width: 198,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 6,
      }}
    >
      <ClickAwayListener onClickAway={(e)=>props.handleClose(e, 'f')}>
        <Paper
          className={classes.block}
          style={{ display: "flex", paddingLeft: 15,  paddingRight: 15, boxSizing: "border-box", flexDirection: 'column', boxShadow: '-3px 3px 10px 4px rgba(221, 221, 221, 1)', }}
        >
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            From the list of devices select the device you want to <b style={props.styleValue}>{props.value}</b> by
            clicking on it. Selected device will change color. Comfirm your
            selection by clicking on the <b style={props.styleValue}>{props.value}</b> button below.
            To exit without <b style={props.styleValue}>{props.value}</b> click Close button.
          </Typography>

          <div style={{                display: "flex",
                justifyContent: "space-between",
                width: "100%",}}>
          <Button
                style={{
                  backgroundColor: "#2E5BFF",
                  color: "#fff",
                  marginTop: 5,
                  height: 30,
                  width: 79,

                }}
                variant="contained"
                onClick={
                  props.value==='start'?
                  (evt)=> start(evt, startStopDevices.device_key) :
                  (evt)=>stop(evt, startStopDevices.device_key)}
                margin="normal"
              >
                {props.value}
              </Button>
              <Button
                style={{
                  backgroundColor: "#FF2E2E",
                  color: "#fff",
                  marginTop: 5,
                  height: 30,
                  width: 79,
                }}
                variant="contained"
                onClick={(e)=>{
                  props.sop(false);props.sat(false);
                   setStartStopMode(false);props.handleClose(e, false)}}
              >
                Close
              </Button>
          </div>
        </Paper>
      </ClickAwayListener>
    </div>
  );
};

export default StartStopDeviceComponent;
