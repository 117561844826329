import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";

import { Typography } from '@material-ui/core';
import { useEffect } from 'react';
import  momentTimeZ  from 'moment-timezone';
const StyledTableCell = withStyles((theme) => ({
    head: {
        fontFamily:'Roboto',
        fontSize: 14,
        fontWeight:'bold',
        textTransform: 'uppercase'
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      maxHeight: 35,
      height: 35
    },
  }))(TableRow);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

const roundNumber = (num) => {
  return Number.isInteger(num) ? num : parseFloat(num.toFixed(2))
}
  const useStyles = makeStyles((theme)=>({
    table: {
      minWidth: 700,
      maxHeight: 310
    },
    tableWrap: {
      width:'85.5%', marginTop: 10,
      [theme.breakpoints.down("xs")]: {
        width: "100%",


      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  }));

  const getFormatDate = (data, format) => {

    let tiz = localStorage.getItem('timeZone');
    let formatDate;
    if(tiz) {
      formatDate = momentTimeZ(data).tz(tiz).format(format)

      return formatDate;
    }
    formatDate = momentTimeZ(data).format(format) // "1 Haziran 2017"
    formatDate = formatDate.substring(0, formatDate.length - 6);

    return formatDate;
  }

  const getFormatTime = (time, format) => {

    let tiz = localStorage.getItem('timeZone');
    let formatTime;
    if(tiz) {
      formatTime = momentTimeZ(time).tz(tiz).format(format)

      return formatTime;
    }
    formatTime = momentTimeZ(time).format(format) // "1 Haziran 2017"

    return formatTime;
  }



  const DataTableCompanent = (props) => {
    const isHaveTds = props.deviceInfoArr ?  props.deviceInfoArr.some(el=>el.tds&&el.tds > (-1)) : false;
    const isHavePh = props.deviceInfoArr ?  props.deviceInfoArr.some(el=>el.ph&&el.ph > (-1)) : false;
  return props.deviceInfoArr ? props.deviceInfoArr.map((row, i) => (
    <StyledTableRow key={props.mul ? row.deviceName + `${Math.random()}`: i+`${Math.random()}`}>
      <StyledTableCell component="th" scope="row">
      <Typography style={ {fontWeight: 'normal', fontSize:12}}>{getFormatDate(row.date_cr, 'll')}</Typography>
      </StyledTableCell>
      <StyledTableCell ><Typography style={ {fontWeight: 'normal', fontSize:12}}>{getFormatTime(row.date_cr,'HH:mmA')}</Typography></StyledTableCell>
      <StyledTableCell ><Typography style={ {fontWeight: 'normal', fontSize:12}}>{props.mul  ? row.deviceName : props?.currentDevice?.name ? props.currentDevice.name : ''}</Typography></StyledTableCell>
      <StyledTableCell ><Typography style={row.absorb < 1 ? {color: '#00C99B', fontWeight: 'normal', fontSize:12} : { color: '#FF2E2E', fontWeight: 'normal', fontSize:12}}>{row.absorb < 1 ? 'Normal' :'Contamination' }</Typography></StyledTableCell>
      <StyledTableCell >{row.absorb.toFixed(3)}</StyledTableCell>
      {
        isHaveTds ? <>
          <StyledTableCell >{row?.tds&&row.tds<0?'' : roundNumber(row.tds) }</StyledTableCell>
          <StyledTableCell >{row?.conductivity&&row.conductivity<0?'' : roundNumber(row.conductivity) }</StyledTableCell>
        </> : null
      }
      {isHavePh ? <StyledTableCell >{row?.ph&&row.ph<0?'' : roundNumber(row.ph) }</StyledTableCell> : null}
    </StyledTableRow>)
  )
      : null};

  export default function TableComponent(props) {
    const [arrData, setArrData] = React.useState([]);
    const [data, setData] = React.useState([]);
    const devicesMultilineList = useSelector(state => state.deviceReducer.devicesMultilineList);
    const multilineMode = useSelector(state => state.deviceReducer.multilineMode);
    const multilineSample = useSelector(state => state.deviceReducer.multilineSample);
    const currentResults = useSelector(state => state.deviceReducer.currentResults);
    // useEffect(()=> {
    //
    //   if(multilineMode&&devicesMultilineList?.length&&multilineSample.size> 0){
    //
    //     let arr=[];
    //     console.log('sdfssdfsfsfsfd', multilineSample);
    //     devicesMultilineList.forEach((item, i)=>{

    //       let value = multilineSample.get(item).map((it)=>({...it, deviceName:item.name}));
    //       console.log(value)
    //       arr = arr.concat(value);
    //       console.log(arr)
    //     })

    //     arr = arr.filter((item)=>currentResults==='normal' ? (item.m1||item.m2 || item.m3)<1 : currentResults==='alert' ? (item.m1||item.m2 || item.m3)>=1 : item).sort(function (a, b) {

    //       return new Date(b.date_cr) - new Date(a.date_cr);
    //     });
    //     console.log(arr)
    //     // arr = arr.slice(0, 200);
    //     console.log(arr)

    //     // for( const [key, value] of multilineSample.entries()) {
    //     //   value.map((item, index) =>{arr.push({
    //     //     name: getFormatDate(item.date_cr),
    //     //     date: index,
    //     //     [key.device_key]: item.absorb,
    //     //     redLine: 1
    //     //   })})

    //     // }
    //     setArrData(arr);
    //     setData(arr);


    //   }
    // }, [multilineMode,devicesMultilineList, multilineSample])



    // useEffect(()=> {

    //   if(data.length&&multilineMode){
    //     if(currentResults==='all') {
    //       setArrData(data)
    //     }
    //     if(currentResults==='normal') {
    //       setArrData(data.filter((item)=>item.absorb<1))
    //     }
    //     if(currentResults==='alert') {
    //       setArrData(data.filter((item)=>item.absorb>=1))
    //     }
    //   }
    // }, [multilineMode, currentResults, data])

    const classes = useStyles();
    const currentDevice = useSelector(state => state.deviceReducer.currentDevice);
    const deviceInfoArr = useSelector(state => state.deviceReducer.currDeviceArr);
    const isHaveTds = deviceInfoArr ?  deviceInfoArr.some(el=>el.tds&&el.tds > (-1)) : false;
    const isHavePh = deviceInfoArr ?  deviceInfoArr.some(el=>el.ph&&el.ph > (-1)) : false;
    // console.log(deviceInfoArr);

    const formatted_list =  React.useMemo(()=>{
      const list_ = deviceInfoArr.map(item => ({
        ...item,
        ph: item.ph < 0 ? undefined : item.ph,
        tds: item.tds < 0 ? undefined : item.tds,
        conductivity: item.tds < 0 ? undefined : item.tds/0.65
      }));
      return list_

    }, [deviceInfoArr]);

    useEffect(()=> {

        switch(currentResults) {
          case 'all':
            setArrData(formatted_list)
            break;
          case 'normal':
            setArrData(formatted_list.filter((item)=>item.absorb<1))
            break;
          case 'alert':
            setArrData(formatted_list.filter((item)=>item.absorb>=1))
            break;
          default:
            return;
        }
      }, [formatted_list, currentResults])


    return (
      <Paper className={classes.tableWrap} >
      <TableContainer component={Paper} style={{width:'100%', marginTop: 10, maxHeight: 310}}>
        <Table className={classes.table} size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Data</StyledTableCell>
              <StyledTableCell >Time</StyledTableCell>
              <StyledTableCell >Device name</StyledTableCell>
              <StyledTableCell >detection results</StyledTableCell>
              <StyledTableCell >detected level</StyledTableCell>
              {
                isHaveTds ? <>
                  <StyledTableCell >Total dissolved solids</StyledTableCell>
                  <StyledTableCell >Conductivity</StyledTableCell>
                </> : null
              }
              {
                isHavePh ?               <StyledTableCell >ph</StyledTableCell> : null
              }

            </TableRow>
          </TableHead>
          <TableBody>
            {formatted_list.length>0 ? <DataTableCompanent deviceInfoArr={arrData} currentDevice={currentDevice} mul={multilineMode}/>
          : !formatted_list || formatted_list.length===0 ?  [1, 2,3,4,5,6,7,8].map((row) => (
              <StyledTableRow key={row+'dfsd'}>
                <StyledTableCell component="th" scope="row">
                <Typography style={ {fontWeight: 'normal', fontSize:12}}></Typography>
                </StyledTableCell>
                <StyledTableCell ><Typography style={ {fontWeight: 'normal', fontSize:12}}></Typography></StyledTableCell>
                <StyledTableCell ><Typography style={ {fontWeight: 'normal', fontSize:12}}></Typography></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>

              </StyledTableRow>
            )) :  [1, 2,3,4,5,6,7,8].map((row) => (
              <StyledTableRow key={row+'dfsd'}>
                <StyledTableCell component="th" scope="row">
                <Typography style={ {fontWeight: 'normal', fontSize:12}}></Typography>
                </StyledTableCell>
                <StyledTableCell ><Typography style={ {fontWeight: 'normal', fontSize:12}}></Typography></StyledTableCell>
                <StyledTableCell ><Typography style={ {fontWeight: 'normal', fontSize:12}}></Typography></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>

              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
    );
  }
