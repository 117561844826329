import React, { useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";

import { useInput } from "./../hooks/useInput";

import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../redux/actions/deviceActions/device.action";
import { Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },

  menuItemBlock: {
    maxWidth: 198,
  },
  textField: {
    width: 80,
    height: 26,
    padding: 0,
    borderColor: "#EAF0F5",
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 5,
  },

  block: {
    width: "100%",
    marginTop: 5,
    backgroundColor: "#fff",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },

  btnMenu: {
    width: 198,
    borderColor: "#2E5BFF",
    borderWidth: 2,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
  },
}));

const InputLabelComponent = (props) => (
  <label
  style={{
    display: "flex",
  //   flexDirection: "column",
  alignItems:'center',
    marginTop: 1,
  }}
>
  <span
    style={{
      fontSize: 12,
      fontFamily: "Roboto",
      fontWeight: 500,
      marginRight:10
    }}
  >
    {props.labelValue}
  </span>
  <input

    style={{    width: 80,
      height: 26,
      padding: 0,
      paddingLeft: 5,
      borderColor: "#EAF0F5",
      borderWidth: 1,
      borderStyle: "solid",
      marginTop: 5,}}
    type="text"
    value={props.value}
    {...props.bindValue}
    name={props.name}
  />
</label>
);


const DeviceSettingComponent = (props) => {

  const dispatch = useDispatch();

  const saveSetting = useCallback((body, devId) => dispatch(actions.saveDevSetting(body, devId)), [
    dispatch,
  ]);

  const [dis, setDis] = React.useState(true);
  const currentDevice = useSelector(state => state.deviceReducer.currentDevice);
  const [averageMeasuring, setAverageMeasuring] = React.useState(currentDevice.sentens.status_avg);
  const [enableDisplayOff, setEnableDisplayOff] = React.useState(currentDevice.sentens.status_backlight);
 useEffect(()=> {
   if(!currentDevice.version.modul){
    setDis(false);
    return;
   }
   if(Number(currentDevice.version.modul[currentDevice.version.modul.length-1]) > 8){
     setDis(false)
   }
 }, [currentDevice])
  const [useScreenSaver, setUseScreenSaver] = React.useState(currentDevice.sentens.status_scrsaver);
  const [enableRelay, setEnableRelay] = React.useState(currentDevice.sentens.status_relay);
  const [alarmTel, setAlarmTel] = React.useState(currentDevice.sentens.tel);
  const [alarmMail, setAlarmMail] = React.useState(currentDevice.sentens.tits_mail);
  const classes = useStyles();
  const {
    value: contaminationThreshold,
    bind: bindContaminationThreshold,
    reset: resetContaminationThreshold,
  } = useInput(currentDevice.sentens.absorb_cange/1000);

  const { value: DetectionNumber, bind: bindDetectionNumber, reset: resetDetectionNumber } = useInput(
    currentDevice.sentens.detect_numb/1000
  );

  const {
    value: ValidDetection,
    bind: bindValidDetection,
    reset: resetValidDetection,
  } = useInput(currentDevice.sentens.detect_valid/1000);

  const { value: FirstDetectionDelay, bind: bindFirstDetectionDelay, reset: resetFirstDetectionDelay } = useInput(
    currentDevice.sentens.detect_delay/1000
  );
  const {
    value: AnalyzingPeriod,
    bind: bindAnalyzingPeriod,
    reset: resetAnalyzingPeriod,
  } = useInput(currentDevice.sentens.pause/1000);

  const { value: RinsePeriod, bind: bindRinsePeriod, reset: resetRinsePeriod } = useInput(
    currentDevice.sentens.rinse_period/1000
  );
  const {
    value: RinseTime,
    bind: bindRinseTime,
    reset: resetRinseTime,
  } = useInput(currentDevice.sentens.rince_width/1000);

  const { value: DisplayOffTime, bind: bindDisplayOffTime, reset: resetDisplayOffTime } = useInput(
    currentDevice.sentens.baclight_delay/1000
  );
  const {
    value: CleaningTime,
    bind: bindCleaningTime,
    reset: resetCleaningTime,
  } = useInput(currentDevice.sentens.clean_time/1000);
  const { value: CleaningThreshold, bind: bindCleaningThreshold, reset: resetCleaningThreshold } = useInput(
    currentDevice.sentens.clean_tresh/1000
  );

  const save = (evt)=> {
    let saveRequest = '';
    if(averageMeasuring!==currentDevice.sentens.status_avg){
      saveRequest = saveRequest+`1:${Number(averageMeasuring)},`
    }
    if(enableDisplayOff!==currentDevice.sentens.status_backlight){
      saveRequest = saveRequest+`2:${Number(enableDisplayOff)},`
    }
    if(useScreenSaver!==currentDevice.sentens.status_scrsaver){
      saveRequest = saveRequest+`3:${Number(useScreenSaver)},`
    }
    if(enableRelay!==currentDevice.sentens.status_relay){
      saveRequest = saveRequest+`4:${Number(enableRelay)},`
    }

    if(contaminationThreshold*1000!==currentDevice.sentens.absorb_cange){
      saveRequest = saveRequest+`5:${contaminationThreshold/**1000*/},`
    }
    if(DetectionNumber*1000!==currentDevice.sentens.detect_numb){
      saveRequest = saveRequest+`8:${DetectionNumber/**1000*/},`
    }
    if(ValidDetection*1000!==currentDevice.sentens.detect_valid){
      saveRequest = saveRequest+`6:${ValidDetection/**1000*/},`
    }
    if(FirstDetectionDelay*1000!==currentDevice.sentens.detect_delay){
      saveRequest = saveRequest+`10:${FirstDetectionDelay/**1000*/},`
    }
    if(AnalyzingPeriod*1000!==currentDevice.sentens.pause){
      saveRequest = saveRequest+`9:${AnalyzingPeriod/**1000*/},`
    }
    if(RinsePeriod*1000!==currentDevice.sentens.rinse_period){
      saveRequest = saveRequest+`11:${RinsePeriod/**1000*/},`
    }
    if(RinseTime*1000!==currentDevice.sentens.rince_width){
      saveRequest = saveRequest+`12:${RinseTime/**1000*/},`
    }
    if(DisplayOffTime*1000!==currentDevice.sentens.baclight_delay){
      saveRequest = saveRequest+`13:${DisplayOffTime/**1000*/},`
    }
    if(CleaningTime*1000!==currentDevice.sentens.clean_time){
      saveRequest = saveRequest+`14:${CleaningTime/**1000*/},`
    }
    if(CleaningThreshold*1000!==currentDevice.sentens.clean_tresh){
      saveRequest = saveRequest+`15:${CleaningThreshold/**1000*/},`
    }

    if(saveRequest.length>0){
      saveRequest.substring(0, saveRequest.length - 1);
    }

    saveSetting(saveRequest, currentDevice.device_key);
    

    props.handleClose(evt);
  }

  
  const SwitchComponent = (props) => {console.log('dfgdgfd', props); return (
    
    <div style={{ display: "flex" }}>
    <Button
      style={
        props.themeCond
          ? {
              backgroundColor: "#00C99B",
              color: "#fff",
              marginTop: 5,
              height: 30,
              minWidth: 40,
              width: 40,
            }
          : {
              backgroundColor: "#EAF0F5",
              color: "#fff",
              marginTop: 5,
              height: 30,
              minWidth: 40,
              width: 40,
            }
      }
      variant="contained"
      onClick={() => props.changeSwitch(true)}
    >
      On
    </Button>
    <Button
      style={   !props.themeCond
        ? {
            backgroundColor: "#FF2E2E",
            color: "#fff",
            marginTop: 5,
            height: 30,
            minWidth: 40,
            width: 40,
          }
        : {
            backgroundColor: "#EAF0F5",
            color: "#fff",
            marginTop: 5,
            height: 30,
            minWidth: 40,
            width: 40,
          }}
      variant="contained"
      onClick={() => props.changeSwitch(false)}
    >
      Off
    </Button>
  </div>
  )};



  return (
    <div
      style={{
        width: 259,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 6,
      }}
    >
      <ClickAwayListener onClickAway={props.handleClose}>
        <Paper className={classes.block} style={{ display: "flex", boxShadow: '-3px 3px 10px 4px rgba(221, 221, 221, 1)', }}>
       { dis ?  <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div style={{display: 'flex'}}>
              <p style={{fontSize: 12, fontWeight: 500, marginRight: 10}}>Average Measuring:</p>
              <SwitchComponent changeSwitch={setAverageMeasuring} themeCond={averageMeasuring}/>
            </div>
            <div style={{display: 'flex'}}>
              <p style={{fontSize: 12, fontWeight: 500, marginRight: 10}}>Enable Display Off:</p>
              <SwitchComponent changeSwitch={setEnableDisplayOff}  themeCond={enableDisplayOff}/>
            </div>
            <div style={{display: 'flex'}}>
              <p style={{fontSize: 12, fontWeight: 500, marginRight: 10}}>Use Screen Saver:</p>
              <SwitchComponent changeSwitch={setUseScreenSaver}  themeCond={useScreenSaver}/>
            </div>
            
            <div style={{display: 'flex'}}>
              <p style={{fontSize: 12, fontWeight: 500, marginRight: 10}}>Enable Relay:</p>
              <SwitchComponent changeSwitch={setEnableRelay}  themeCond={enableRelay}/>
            </div>

            {/* <div style={{display: 'flex'}}>
              <p style={{fontSize: 12, fontWeight: 500, marginRight: 10}}>Alarm tel:</p>
              <SwitchComponent changeSwitch={setAlarmTel}  themeCond={alarmTel}/>
            </div>

            <div style={{display: 'flex'}}>
              <p style={{fontSize: 12, fontWeight: 500, marginRight: 10}}>Alarm mail:</p>
              <SwitchComponent changeSwitch={setAlarmMail}  themeCond={alarmMail}/>
            </div> */}

            

            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
                <InputLabelComponent value={contaminationThreshold} bindValue={bindContaminationThreshold} name={'contaminationThreshold'} labelValue={'Contamination Threshold:'}/>
                <InputLabelComponent value={ValidDetection} bindValue={bindValidDetection} name={'ValidDetection'} labelValue={'Valid Detection:'}/>
                <InputLabelComponent value={DetectionNumber} bindValue={bindDetectionNumber} name={'DetectionNumber'} labelValue={'Detection Number:'}/>
                <InputLabelComponent value={FirstDetectionDelay} bindValue={bindFirstDetectionDelay} name={'FirstDetectionDelay'} labelValue={'First Detection Delay, sec:'}/>
                <InputLabelComponent value={AnalyzingPeriod} bindValue={bindAnalyzingPeriod} name={'AnalyzingPeriod'} labelValue={'Analyzing Period, min:'}/>
                <InputLabelComponent value={RinsePeriod} bindValue={bindRinsePeriod} name={'RinsePeriod'} labelValue={'Rinse Period, min:'}/>
                <InputLabelComponent value={RinseTime} bindValue={bindRinseTime} name={'RinseTime'} labelValue={'Rinse Time, sec:'}/>
                <InputLabelComponent value={DisplayOffTime} bindValue={bindDisplayOffTime} name={'DisplayOffTime'} labelValue={'Display Off Time, sec:'}/>
                <InputLabelComponent value={CleaningTime} bindValue={bindCleaningTime} name={'CleaningTime'} labelValue={'Cleaning Time, sec:'}/>
                <InputLabelComponent value={CleaningThreshold} bindValue={bindCleaningThreshold} name={'CleaningThreshold'} labelValue={'Cleaning Threshold, %:'}/>
            </form>

            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#00C99B",
                  color: "#fff",
                  marginTop: 5,
                  height: 30,
                  width: 110,
                }}
                variant="contained"
                onClick={(evt) => save(evt)}
                margin="normal"
              >
                Save
              </Button>
              <Button
                style={{
                  backgroundColor: "#B0BAC9",
                  color: "#fff",
                  marginTop: 5,
                  height: 30,
                  width: 110,
                }}
                variant="contained"
                onClick={props.handleClose}
              >
                Close
              </Button>
            </p>
          </div> : <Typography style={{marginLeft: 10}}>This function is not activated in your device.  Contact support by phone 201-777-4556</Typography>}
        </Paper>
      </ClickAwayListener>
    </div>
  );
};

export default DeviceSettingComponent;
