import DeviceService from "../../../services/device.service";
import * as actionTypes from "./actions.types";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import  momentTimeZ  from 'moment-timezone';
import { ToastProvider, useToasts } from 'react-toast-notifications';
export function initWebSocketConnection(dispatch) {}

const createArrWithStatus = (arr, maxValue) => {
  let newArr = [];
  arr.forEach((element) => {
    newArr.push({
      ...element,
      status:
        moment(maxValue).diff(element.update) < 20000
          ? "online"
          : moment(maxValue).diff(element.update) < 60000
          ? "sleep"
          : "offline",
    });
  });
  return newArr;
};

export function getDevicesList() {

  return async (dispatch) => {
    await DeviceService.deviceList()
      .then(async (response) => {
        if (response.code === 200 || response.code === 201) {

          if(!response.info.dev_arr.arr?.length){
            return;
          }
          let arr = await createArrWithStatus(
            response.info.dev_arr.arr,
            response.data
          );


          dispatch(setUserInfo(response.info.user_info))
          dispatch(setDevicesList(arr));
        } else {
          alert('Error connect to server');
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        alert('Error connect to server');
        console.log(error);
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
    // return async (dispatch) => {
    //

    // };
  };
}

export function getDeviceInfo(devId, dateModificators, isSelect) {
  return async (dispatch) => {

    dispatch(setIsLoad(true))
    await DeviceService.deviceInfoCalc(devId, dateModificators)
      .then((response) => {


        if (response.code === 200 || response.code === 201) {
          dispatch(setСurrMaxSize(response.info.arr[0].size));
          if (response.info.arr[0].size >= 100) {
            // dispatch(setRange([0, 100]));

              dispatch(getArrForRange(devId, [0, response.info.arr[0].size], dateModificators, isSelect))
              return;

          }
          if (response.info.arr[0].size < 100) {
            dispatch(setRange([0, response.info.arr[0].size]));
            dispatch(getArrForRange(devId,[0, response.info.arr[0].size], dateModificators))
          }
        } else {

          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}

export function getArrForRange(devId, range,dateModificators, isSelect) {
  return async (dispatch) => {
    // await DeviceService.deviceInfoAll(devId, range, dateModificators).then((res)=> {
    //
    // })
    //
    await DeviceService.deviceInfo(devId, range, dateModificators)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {
          let newArr;
          // console.log('dfgfgdfgdg', response.info.begin_time);
          dispatch(setCurrentPeriod([response.info.begin_time, response.info.end_time]))

          let timezone = localStorage.getItem('timeZone');
          let redLine = Math.max(...response.info.arr.map((item)=>item.absorb)) > 1 ? 1 : null;
          if(timezone){

            newArr = response.info.arr.map((item)=>{
              return {...item,
                date_cr: momentTimeZ.unix(item.date_cr).tz(timezone).format(),
                name: getFormatDate(momentTimeZ.unix(item.date_cr).tz(timezone).format()),
                dirty: item.absorb,
                realDate: momentTimeZ.unix(item.date_cr).tz(timezone).format(),
                redLine
              }

            })
          } else {
            newArr = response.info.arr.map((item)=>{

              return {...item,
                date_cr: momentTimeZ.unix(item.date_cr).format(),
                name: getFormatDate(momentTimeZ.unix(item.date_cr).format()),
                dirty: item.absorb,
                realDate: momentTimeZ.unix(item.date_cr).format(),
                redLine

              }
            })
          }


          newArr = newArr.sort(function (a, b) {
            return new Date(a.date_cr) - new Date(b.date_cr) ;
          });
          newArr = newArr.reverse();

          if(dateModificators){
            dispatch(setRange([0,newArr.length]));
            dispatch(setCurrentDeviceArr(newArr));
          }
          // else {
          //   dispatch(setRange([0,100]));
          //   dispatch(setCurrentDeviceArr(newArr.slice(0,100)));
          // }

          dispatch(setFullList(newArr));
          dispatch(setCurrentResults('all'))
        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {

        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}


export function rename(name, devId,) {
  return async (dispatch) => {

    await DeviceService.renameDevice(name, devId)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {
          dispatch(setNameDevice(response.info.dev.name))
        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}

export function deleteDev(devId) {
  return async (dispatch) => {

    await DeviceService.deleteDevice( devId)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {

        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}

export function saveDevSetting(body, devId) {
  return async (dispatch) => {
    await DeviceService.saveDeviceSetting(body, devId)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {

        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}

export function startDev(devId) {
  return async (dispatch) => {

    await DeviceService.startDevice(devId)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {

        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}

export function stopDev(devId) {
  return async (dispatch) => {
    await DeviceService.stopDevice(devId)
      .then((response) => {
        if (response.code === 200 || response.code === 201) {

        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}

export function addDev(name,devId) {
  return async (dispatch) => {
   return await DeviceService.addDevice(devId)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {
          DeviceService.renameDevice(name, devId)
          .then((response) => {

            if (response.code === 200 || response.code === 201) {
              return true
            } else {
              // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
              return;
            }
          })
          .catch((error) => {
            //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
            //   dispatch(setIsFetching(false));
          });
        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}

export function addPhone(tel) {
  return async (dispatch) => {
    await DeviceService.addPhoneInAlert(tel)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {
          dispatch(getPhonesAndMails())
        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}

export function addMail(mail) {
  return async (dispatch) => {
    await DeviceService.addMailInAlert(mail)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {
          dispatch(getPhonesAndMails())
        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}

export function removeAlert(al_id) {
  return async (dispatch) => {
    await DeviceService.removeAlert(al_id)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {
          dispatch(getPhonesAndMails())
        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
  };
}



export function sampleMultilines(devIds, dateModificators) {
  return async (dispatch) => {

    let sizes=[];

    for await (let  item of devIds){
      await DeviceService.deviceInfoCalc(item.device_key, dateModificators).then((response)=> {

        if (response.code === 200 || response.code === 201) {
          sizes.push(response.info.arr[0].size)
      }

      })
    }
    let maxSize = await Math.max(...sizes);
    dispatch(setСurrMaxSize(maxSize));
    if(dateModificators){
      dispatch(getArrForMultilineSample(devIds, [0, maxSize], dateModificators));
      return;
    }
    dispatch(getArrForMultilineSample(devIds, [0, 100], dateModificators))
    // dispatch(setIsLoad(false));
  };
}



const getFormatDate = (data, format) => {

  let tiz = localStorage.getItem('timeZone');
  let formatDate;
  if(tiz) {
    formatDate = momentTimeZ(data).tz(tiz).format('ll')
    formatDate = formatDate.substring(0, formatDate.length - 6);

    return formatDate;
  }
  formatDate = momentTimeZ(data).format('ll') // "1 Haziran 2017"
  formatDate = formatDate.substring(0, formatDate.length - 6);

  return formatDate;
}


export function getArrForMultilineSample(devIds, range,dateModificators) {
  return async (dispatch) => {
    let arrs = [];
    let  id = 0;

    // dispatch(setRange([0, 100]));
    for await (let  [index, item] of devIds.entries()){
      let response = await DeviceService.deviceInfo(item.device_key, range, dateModificators)
      if (response.code === 200 || response.code === 201) {
        let newArr;
        dispatch(setCurrentPeriod([response.info.begin_time, response.info.end_time]))
        dispatch(setRange(range));
        let timezone = localStorage.getItem('timeZone');

        if(timezone){

          newArr = response.info.arr.map((elem, i)=>{
            id =id+1;
            return  {...elem,
              id: id,
              date_cr: momentTimeZ.unix(elem.date_cr).tz(timezone).format(),
              deviceName: item.name,
              date: i,
              name: getFormatDate(momentTimeZ.unix(elem.date_cr).tz(timezone).format()),
              realDate: elem.date_cr,
              redLine: 1,
              [`m${index+1}`]: elem.absorb,
            }
          })
          // arrs.set(item, newArr)

        } else {
          newArr = response.info.arr.map((elem, i)=>{
            id =id+1;
            return {...elem,
              id: id,
              date_cr: momentTimeZ.unix(elem.date_cr).format(),
              deviceName: item.name,
              date: i,
              name: getFormatDate(momentTimeZ.unix(elem.date_cr).format()),
              realDate: elem.date_cr,
              redLine: 1,
              [`m${index+1}`]: elem.absorb,
            }
          })
          //  arrs.set(item, newArr)
        }
        arrs = arrs.concat(newArr);
      }
    }
    arrs = arrs.sort(function (a, b) {
      return new Date(a.date_cr) - new Date(b.date_cr)  ;
    });


    let rhombArr =[];

    let m1ps = [];
    let m2ps = [];
    let m3ps = [];
    let i = 0;
    while(i<arrs.length){
      let newArr = [];
      let newFullArr = [];
      newFullArr = newFullArr.concat(arrs);
      newArr = arrs.slice(i, i+100);
      let a = newArr.find((item)=>item.m1);
      let b = newArr.find((item)=>item.m2);
      let c = newArr.find((item)=>item.m3);
      let d = newFullArr.slice(i+100, i+100+100).find((item)=>item.m1);
      let f =  newFullArr.slice(i+100, i+100+100).find((item)=>item.m2);
      let g =  newFullArr.slice(i+100, i+100+100).find((item)=>item.m3);
      let e = newFullArr.slice(i-100, i).find((item)=>item.m1);
      let y =  newFullArr.slice(i-100, i).find((item)=>item.m2);
      let u =  newFullArr.slice(i-100, i).find((item)=>item.m3);

      let full_m1 = newFullArr.slice(i+100, newFullArr.length).find((item)=>item.m1);
      let full_m1d = newFullArr.slice(0, i).find((item)=>item.m1);
      let full_m2 = newFullArr.slice(i+100, newFullArr.length).find((item)=>item.m2);
      let full_m2d = newFullArr.slice(0, i).find((item)=>item.m2);
      let full_m3 = newFullArr.slice(i+100, newFullArr.length).find((item)=>item.m3);
      let full_m3d = newFullArr.slice(0, i).find((item)=>item.m3);


      if(a&&!d&&full_m1){
        let t = newArr.reverse().find((item)=>item.m1);
        let i = newArr.reverse().findIndex((item)=>item.realDate===t.realDate&&item.absorb===t.absorb);
        rhombArr.push(newArr[i].id);
        // setIds([...ids, newArr[i].id ])
      }
      if(b&&!f&&full_m2){
        let t = newArr.reverse().find((item)=>item.m2);
        let i = newArr.reverse().findIndex((item)=>item.realDate===t.realDate&&item.absorb===t.absorb);
        rhombArr.push(newArr[i].id);
        // setIds([...ids, newArr[i].id ])
      }
      if(c&&!g&&full_m3){
        let t = newArr.reverse().find((item)=>item.m3);
        let i = newArr.reverse().findIndex((item)=>item.realDate===t.realDate&&item.absorb===t.absorb);
        rhombArr.push(newArr[i].id);
        // setIds([...ids, newArr[i].id ])
      }

      if(a&&!e&&full_m1d){
        let t = newArr.find((item)=>item.m1);
        let i = newArr.findIndex((item)=>item.realDate===t.realDate&&item.absorb===t.absorb);
        rhombArr.push(newArr[i].id);
        // setIds([...ids, newArr[i].id ])
      }
      if(b&&!y&&full_m2d){
        let t = newArr.find((item)=>item.m2);
        let i = newArr.findIndex((item)=>item.realDate===t.realDate&&item.absorb===t.absorb);
        rhombArr.push(newArr[i].id);
        // setIds([...ids, newArr[i].id ])
      }
      if(c&&!u&&full_m3d){
        let t = newArr.find((item)=>item.m3);
        let i = newArr.findIndex((item)=>item.realDate===t.realDate&&item.absorb===t.absorb);
        rhombArr.push(newArr[i].id);
      }
      i= i+100;
    }


    // console.log(rhombArr);

    if(rhombArr.length){
      rhombArr.forEach((it)=>{
        let i = arrs.findIndex((item)=>item.id===it);
        arrs[i]={...arrs[i], rhomb: true}
      })
    }

    // if(m1ps.length){
    //   m1ps.forEach((it)=>{
    //     let i = arrs.findIndex((item)=>item.id===it);
    //     arrs[i]={...arrs[i], m1p: arrs[i].absorb}
    //     console.log(arrs[i]);
    //   })
    // }

    // if(m2ps.length){
    //   m2ps.forEach((it)=>{
    //     let i = arrs.findIndex((item)=>item.id===it);
    //     arrs[i]={...arrs[i],m2p: arrs[i].absorb}
    //     console.log(arrs[i]);
    //   })
    // }

    // if(m3ps.length){
    //   m3ps.forEach((it)=>{
    //     let i = arrs.findIndex((item)=>item.id===it);
    //     arrs[i]={...arrs[i], m2p: arrs[i].absorb}
    //     console.log(arrs[i]);
    //   })
    // }
    arrs =  arrs.reverse();
    if(dateModificators){
      dispatch(setRange([0,arrs.length]));
      dispatch(setCurrentDeviceArr(arrs));
      dispatch(setFullList(arrs))
      dispatch(setCurrentResults('all'))
    } else {
      dispatch(setRange([0,100]));
      dispatch(setCurrentDeviceArr(arrs.slice(0,100)));
      dispatch(setFullList(arrs.slice(0,100)))
      dispatch(setCurrentResults('all'))
    }



    // dispatch(setMultilineSample(arrs));
    // dispatch(setIsLoad(false));
  }
  }



export function getPhonesAndMails() {
  return async (dispatch) => {
    let phonesAndMails ={};
    await DeviceService.getPhonesAndMails(7)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {
          phonesAndMails.mails= response.info.allert_arr.filter((item)=>item.type===7);
        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });

    await DeviceService.getPhonesAndMails(8)
      .then((response) => {

        if (response.code === 200 || response.code === 201) {
          phonesAndMails.phones= response.info.allert_arr.filter((item)=>item.type===8);
        } else {
          // addToast(response.data.msg,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
          return;
        }
      })
      .catch((error) => {
        //   addToast(error.response.data,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        //   dispatch(setIsFetching(false));
      });
      await dispatch(setPhonesAndMails(phonesAndMails))
  };
}

export function setCurrentResults(currentResults) {

  return {
    type: actionTypes.CURRENT_RESULTS,
    currentResults,
  };
}


export function setDeleteDevMode(deleteDevModeStatus) {

  return {
    type: actionTypes.DELETE_DEV_MODE,
    deleteDevModeStatus,
  };
}
export function setDeleteDevice(devOnDel) {

  return {
    type: actionTypes.DELETE_DEV,
    devOnDel,
  };
}

export function setStartStopMode(start_stop_mode) {

  return {
    type: actionTypes.START_STOP_MODE,
    start_stop_mode,
  };
}

export function setStartStopDevices(startStopDevices) {
  return {
    type: actionTypes.START_STOP_DEVICES,
    startStopDevices,
  };
}


export function setNameDevice(name) {

  return {
    type: actionTypes.RENAME,
    name,
  };
}

export function setFullList(fullList) {

  return {
    type: actionTypes.FULL_LIST,
    fullList,
  };
}


export function setNotDisable(notDisable) {

  return {
    type: actionTypes.NOT_DISABLE,
    notDisable,
  };
}


export function setPhonesAndMails(phonesAndMails) {

  return {
    type: actionTypes.PHONES_AND_MAILS,
    phonesAndMails,
  };
}

export function setUserInfo(userInfo) {

  return {
    type: actionTypes.CURRENT_USER_INFO,
    userInfo,
  };
}

export function setIsLoad(isLoad) {

  return {
    type: actionTypes.IS_LOAD,
    isLoad,
  };
}

export function setIsSelPer(isSelPer) {

  return {
    type: actionTypes.IS_SELECTED_PERIOD_SAMPLE,
    isSelPer,
  };
}

export function setMultilineSample(multilineSample) {
  return {
    type: actionTypes.MULTILINE_SAMPLE,
    multilineSample,
  };
}


export function setСurrMaxSize(currMaxSize) {
  // console.log(currMaxSize);
  return {
    type: actionTypes.CURR_MAX_SIZE,
    currMaxSize,
  };
}

export function setDevicesList(devicesList) {
  // console.log(devicesList);
  return {
    type: actionTypes.DEVICES_LIST,
    devicesList,
  };
}

export function setСurrDeviceArr(currDeviceArr) {
  // console.log(currDeviceArr);
  return {
    type: actionTypes.CURRENT_DEVICE_ARR,
    currDeviceArr,
  };
}

export function setRange(range) {
  // console.log(range);
  return {
    type: actionTypes.RANGE,
    range,
  };
}

export function setCurrentDevice(currentDevice) {
  return {
    type: actionTypes.CURRENT_DEVICE,
    currentDevice,
  };
}

export function setDevicesMultilineList(devicesMultilineList) {
  return {
    type: actionTypes.DEVICES_MULTILINE,
    devicesMultilineList,
  };
}

export function setMultilineMode(multilineMode) {
  return {
    type: actionTypes.MULTILINE_MODE,
    multilineMode,
  };
}


export function setCurrentDeviceInfo(currentDeviceInfo) {
  return {
    type: actionTypes.CURRENT_DEVICE_INFO,
    currentDeviceInfo,
  };
}

export function setCurrentDeviceArr(currDeviceArr) {
  return {
    type: actionTypes.CURRENT_DEVICE_ARR,
    currDeviceArr,
  };
}

//   export function setCurrentArrData(currentArrData) {
//     return {
//       type: actionTypes.CURRENT_ARR_DATA,
//       currentArrData,
//     };
//   }


export function setCurrentPeriod(currentPeriod) {
  return {
    type: actionTypes.CURRENT_PERIOD,
    currentPeriod,
  };
}
