import React, { useEffect, useCallback } from "react";
import { Hidden, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import QrcodeDecoder from 'qrcode-decoder';

import * as actions from "../../redux/actions/deviceActions/device.action";
import QrReader from "react-qr-scanner";
import { useInput } from "./../hooks/useInput";
import { useDispatch, useSelector } from "react-redux";
import jsQR from "jsqr";
import DeviceService from "../../services/device.service";
import CalibrateComponent from './calibrate_component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },

  menuItemBlock: {
    maxWidth: 198,
  },

  textField: {
    width: 142,
    height: 26,
    padding: 0,
    marginLeft: 12,
    borderColor: "#EAF0F5",
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 5,
  },

  btnMenu: {
    width: 198,
    borderColor: "#2E5BFF",
    borderWidth: 2,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
  },
  block: {
    width: "100%",
    marginTop: 5,
    backgroundColor: "#fff",
    cursor: "pointer",
    height: 40,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderWidth: 2,
  },
  statusColo: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    width: 8,
    height: 8,
    borderRadius: 50,
      left: '10px'
    // marginRight: 10,
    // marginLeft: 15,
  },
}));

const DeviceListComponent = (props) => {
  const dispatch = useDispatch();

  const [openAddDeviceDialog, setOpenAddDeviceDialog] = React.useState(false);
  const deviceList = useSelector((state) => state.deviceReducer.deviceList);
  const [bordState, setBordState] = React.useState({});
  const [count, setCount] = React.useState(0);
  const [list, setList] = React.useState(props.deviceList ?? []);
  const [file_name, set_file_name] = React.useState('choose QRcode');
  const [file_qr, set_file_qr] = React.useState(null);
  const [file_error, set_file_error] = React.useState(null);
  const [file_success, set_file_success] = React.useState(null);

  const sampleMultilines = useCallback(
    (deviceList) => dispatch(actions.sampleMultilines(deviceList)),
    [dispatch]
  );

  const addDev =
    async (name, devId) => {

        return await DeviceService.addDevice(devId)
           .then((response) => {

             if (response.code === 200 || response.code === 201) {
               return DeviceService.renameDevice(name, devId)
               .then((response) => {

                 if (response.code === 200 || response.code === 201) {
                   return true
                 } else {
                   return;
                 }
               })
               .catch((error) => {
               });
             } else {
               return;
             }
           })
           .catch((error) => {
           });

    }


  const currentDevice = useSelector(
    (state) => state.deviceReducer.currentDevice
  );

  const multilineMode = useSelector(
    (state) => state.deviceReducer.multilineMode
  );

  const devicesMultilineList = useSelector(
    (state) => state.deviceReducer.devicesMultilineList
  );
  const {
    value: addDeviceID,
    bind: bindAddDeviceID,
    reset: resetAddDeviceID,
    setValue: set_new_device_id
  } = useInput("");
  const {
    value: addDeviceName,
    bind: bindAddDeviceName,
    reset: resetAddDeviceName,

  } = useInput("");
  // console.log("sdfsdf", props);
  const classes = useStyles();
  // console.log(props.deviceList);
  const setIsLoad = useCallback(
    (isLoad) => dispatch(actions.setIsLoad(isLoad)),
    [dispatch]
  );


  const handleClose = () => {
    setOpenAddDeviceDialog(false);
  };

  const connect = (evt) => {
    if (addDeviceID.length < 8 || addDeviceID.length > 40) {
      return;
    }
    if (!addDeviceName || addDeviceID.length > 40) {
      return;
    }
    addDev(addDeviceName, addDeviceID).then((res)=>{
      if(res){
        handleClose()
      }
    });
    props.handleClose(evt);
  };

  const check_qr_file = async (qr_)=>{
    const qr = new QrcodeDecoder();
    let def_txt = 'choose QRcode';
    const result = await qr.decodeFromImage(qr_);
    // let txt_fn  = document.getElementById('ch_qr_cd');
    if (!result) {
      set_file_success(null)
      set_file_error('error qr')
      // set_file_name(def_txt)
      // txt_fn.innerHTML = def_txt;
      return
    }
    // txt_fn.innerHTML = file_name;
    let res_json = JSON.parse(result.data);
    set_file_error(null)
    set_file_success('success')
    set_new_device_id(res_json[0])
  }


  // useEffect(()=>{
  //
  //   if(props.closePop&&devicesMultilineList.length<2){
  //     exit();
  //     props.closePopF(false)
  //   }

  // }, [props.closePop])
  const startStopDevices = useSelector(
    (state) => state.deviceReducer.start_stop_devices
  );
  const setStartStopDevices = useCallback(
    (device) => dispatch(actions.setStartStopDevices(device)),
    [dispatch]
  );

  const startStopMode = useSelector(
    (state) => state.deviceReducer.start_stop_mode
  );

  const setDevicesMultilineList = useCallback(
    (deviceList) => dispatch(actions.setDevicesMultilineList(deviceList)),
    [dispatch]
  );

  const setMultilineMode = useCallback(
    (mode) => dispatch(actions.setMultilineMode(mode)),
    [dispatch]
  );

  const setMultilineSample = useCallback(
    (sample) => dispatch(actions.setMultilineSample(sample)),
    [dispatch]
  );

  const setDevOnDel = useCallback(
    (dev) => dispatch(actions.setDeleteDevice(dev)),
    [dispatch]
  );

  const setDeleteDevMode = useCallback(
    (status) => dispatch(actions.setDeleteDevMode(status)),
    [dispatch]
  );

  const multilineSample = useSelector(
    (state) => state.deviceReducer.multilineSample
  );

  const devOnDel = useSelector((state) => state.deviceReducer.devOnDel);

  const delete_dev_mode = useSelector(
    (state) => state.deviceReducer.delete_dev_mode
  );

  const isLoad = useSelector((state) => state.deviceReducer.isLoad);
  const selectDevice = useCallback(
    async (device, index) => {
      if (!device) {
        return;
      }

      if (delete_dev_mode) {
        setDevOnDel(device);
        return;
      }
      if (startStopMode) {
        setStartStopDevices(device);
        return;
      }

      //let dateMod = `&dt_1=${moment().format(
      //  "YYYY-MM-DD HH:mm:ss"
      //)}&dt_2=${moment().subtract(7, "d").format("YYYY-MM-DD HH:mm:ss")}`;

      let dateMod = `&dt_1=${moment().toISOString()}&dt_2=${moment().subtract(7, "d").toISOString()}`;

      if (multilineMode) {
        if (
          devicesMultilineList.find(
            (item) => item.device_key === device.device_key
          )
        ) {
          return;
        }

        if (devicesMultilineList.length + 1 === 2) {
          await setNotDisable(false);
        }

        if (devicesMultilineList.length + 1 >= 4) {
          await setIsLoad(true);
          setCount(0);
          await setBordState({});
          await setNotDisable(true);
          await setMultilineMode(false);
          await setDevicesMultilineList([]);
          // await setMultilineSample(new Map());
          dispatch(actions.setCurrentDevice(device));
          dispatch(actions.getDeviceInfo(device.device_key, dateMod));
          return;
        }
        if (devicesMultilineList.length + 1 === 3) {
          setCount(count + 1);
          setBordState({ ...bordState, [device.device_key]: count + 1 });
          await setIsLoad(true);
          // console.log(isLoad);
          let arr = devicesMultilineList;
          await arr.push(device);
          await setDevicesMultilineList(arr);

          await sampleMultilines(arr, dateMod);
          return;
        }
        await setCount(count + 1);
        await setBordState({ ...bordState, [device.device_key]: count + 1 });
        let arr = await devicesMultilineList;
        await arr.push(device);
        await setDevicesMultilineList(arr);
        return;
      }

      await setIsLoad(true);
      dispatch(actions.setCurrentDevice(device));
      dispatch(actions.getDeviceInfo(device.device_key, dateMod));
    },
    [
      dispatch,
      multilineMode,
      delete_dev_mode,
      multilineSample,
      devicesMultilineList,
      bordState,
      isLoad,
      count,
      startStopMode,
      startStopDevices,
    ]
  );

  const setNotDisable = useCallback(
    (notDisable) => dispatch(actions.setNotDisable(notDisable)),
    [dispatch]
  );

  const exit = useCallback(async () => {
    await setIsLoad(true);
    setCount(0);
    await setBordState({});
    await setMultilineMode(false);
    await setDevicesMultilineList([]);
    await setMultilineSample(new Map());
    await setNotDisable(true);
    if (currentDevice) {
      dispatch(actions.setCurrentDevice(currentDevice));
      dispatch(actions.getDeviceInfo(currentDevice.device_key));
    }
    if (!currentDevice) {
      dispatch(actions.setCurrentDevice(null));
      dispatch(actions.setCurrentDeviceArr([]));
      dispatch(actions.setFullList([]));
      await setIsLoad(false);
    }
  }, [dispatch, currentDevice]);


    useEffect(() => {
    if (file_qr) {

      check_qr_file(file_qr)
    }
  }, [file_qr]);

  useEffect(() => {
    // if (currentDevice && props.deviceList&&startStopMode) {
    //   selectDevice(currentDevice);
    //   return;
    // }
    if (!currentDevice && props.deviceList) {
      selectDevice(props.deviceList[0]);
      return;
    }

    // if(currentDevice&&props.deviceList){
    //   if(props.deviceList[0].device_key!==currentDevice.device_key){

    //     let newArr = [];
    //     newArr = newArr.concat(props.deviceList);
    //     let  ind = newArr.findIndex((item)=>item.device_key===currentDevice.device_key);
    //     newArr.splice(0, 0, newArr.splice(ind, 1)[0]);
    //     setList(newArr);
    //     return;
    //   }
    // }
    if (props.deviceList) {
      setList(props.deviceList);
    }
  }, [currentDevice, props.deviceList, startStopMode]);

  useEffect(() => {
    if (!multilineMode) {
      setIsLoad(true);
      setCount(0);
      setBordState({});
      setDevicesMultilineList([]);
      setMultilineSample(new Map());
      setNotDisable(true);
      if (currentDevice) {
        dispatch(actions.setCurrentDevice(currentDevice));
        dispatch(actions.getDeviceInfo(currentDevice.device_key));
      }
      if (!currentDevice) {
        dispatch(actions.setCurrentDevice(null));
        dispatch(actions.setCurrentDeviceArr([]));
        dispatch(actions.setFullList([]));
        setIsLoad(false);
      }
    }
  }, [multilineMode]);

  useEffect(() => {
    if (currentDevice) {
      setCount(0);
      setBordState({ [currentDevice.device_key]: count });
      let arr = [];
      arr[0] = currentDevice;
      setDevicesMultilineList(arr);
      return;
    }
  }, [currentDevice]);


  return (
    <ClickAwayListener
      onClickAway={
        props.handleClose ? (e) => props.handleClose(e, false) : () => {}
      }
    >
      <div
      className="hidden_scroll"
        style={
    {
                // width: 195,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                zIndex: 6,
                marginTop: 5,
                maxHeight:800,
        boxSizing: 'border-box',
        padding: '5px'
                // overflowY: 'auto',

              }
        }
      >
        {props.openLint ? (
          <Paper
            className={classes.block}
            style={{
                boxSizing: 'border-box',
              borderColor: "#000",
              borderWidth: 2,
              paddingLeft: 13,
              paddingRight: 13,
              paddingBottom: 10,
              paddingTop: 10,
                height: 'auto',
                width: '100%',
              maxWidth: 173,
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "Roboto",
                fontWeight: "bold",
                // display: "flex",
                // justifyContent: 'center',
              }}
            >
              To view the Device graph want to review, click below on the one
              selected
            </Typography>
          </Paper>
        ) : null}
        <div
        className="hidden_scroll"
        style={{
                          width: '100%',
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          zIndex: 6,
                          marginTop: 5,
                          maxHeight:600,
                          overflowY: 'auto',
        }}>
        {list && list.length > 0 ? (
          <>
            {list.map((item, index) => (
                // <div>test</div>
              // eslint-disable-next-line no-unused-expressions
              <div
                  key={'index-devices'+index}
                variant={"outlined"}
                className={classes.block}
                onClick={() => selectDevice(item, index)}
                style={
                  {
                      width: '100%',
                    padding:12,
                      boxSizing: 'border-box',
                    border: '1px solid',
                      position: 'relative',
                    // width: "168px",
                    marginTop: 5,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                    height: 64,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    ... bordState[item.device_key] === 0 && multilineMode
                    ? {
                        borderColor: "#FF9A00	",
                      }
                    : bordState[item.device_key] === 1 && multilineMode
                    ? {
                        borderColor: "#1067C8",
                      }
                    : bordState[item.device_key] === 2 && multilineMode
                    ? {
                        borderColor: "#00C98B",
                      }
                    : startStopDevices?.device_key === item.device_key &&
                      startStopMode
                    ? { borderColor: "#00C98B" }
                    : devOnDel?.device_key === item.device_key &&
                      delete_dev_mode
                    ? { borderColor: "#00C98B" }
                    : currentDevice?.device_key === item.device_key &&
                      !startStopMode &&
                      !multilineMode &&
                      !delete_dev_mode
                    ? { borderColor: "#00C98B" }
                    : { border: "none" }
                }

                }
              >
                <div
                  className={classes.statusColo}
                  style={
                    item.status === "online"
                      ? { backgroundColor: "#00C99B" }
                      : item.status === "sleep"
                      ? { backgroundColor: "orange" }
                      : {
                          backgroundColor: "#FF2E2E",
                        }
                  }
                ></div>
                <div
                  style={
                    currentDevice?.device_key === item.device_key
                      ? {
                          fontWeight: "bold",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          overflow:'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: 100,
                        paddingLeft: 10
                        }
                      : {
                          fontWeight: "normal",
                          fontSize: 14,
                            overflow:'hidden',
                            textOverflow: 'ellipsis',
                          fontFamily: "Roboto",
                            paddingLeft: 10
                        }
                  }
                >
                  {item.name}
                </div>
              </div>
            ))}
          </>
        ) : (
          <Typography style={{ color: "red" }}>You have no devices</Typography>
        )}

        </div>
        <div

              onClick={() => setOpenAddDeviceDialog(true)}
              style={{
                padding:12,
                border: '1px solid #00C99B',
                width: "100%",
                  boxSizing: 'border-box',
                marginTop: 5,
                backgroundColor: "#fff",
                cursor: "pointer",
                height: 46,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                }}
              >
                Link New Device
              </Typography>
            </div>
            <CalibrateComponent/>
            <Dialog
              open={openAddDeviceDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{ color: "#0F34BB", textAlign: "center" }}
              >
                {"Add Device"}
              </DialogTitle>
              <DialogContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",

                    }}
                  >
                    <div style={{
                      padding: 10,
                      border: '1px solid',
                      borderRadius: 12,
                      cursor: 'pointer'
                    }}>
                    <label
                    style={{
                        minWidth: '100px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                    >

                      <span
                      style={{
                        fontSize: 14,
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        marginRight: file_success || file_error ? 10 : 0,
                        color: file_success ?
                        "#00C99B" : file_error?
                        "#FF2E2E" : "blue"
                      }}>
                        {file_error||file_success}
                      </span>
                      <span
                      id='ch_qr_cd'
                        style={{
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          width: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {file_name}
                      </span>
                      <input
                      style={{display: 'none'}}
                        className={classes.textField}
                        onChange={(e)=>{
                          let file = e.target.files[0];
                          if(!file||!file.type){
                             return
                          }
                          let split_type = file.type.split('/');
                          if (split_type[0]!=='image') {
                            return
                          }
                          set_file_name(file.name.slice(0,15))
                          let reader  = new FileReader();

                          reader.onloadend = function () {

                            set_file_qr(reader.result)
                          }

                          if (file) {
                            reader.readAsDataURL(file);
                          }
                        }}
                        type="file"
                        accept="image/*"
                        name="choose_qr"
                      />
                    </label>
                    </div>

                    <label>
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                      >
                        Enter Device ID:
                      </span>
                      <input
                        className={classes.textField}
                        type="text"
                        {...bindAddDeviceID}
                        name="addDeviceID"
                      />
                    </label>

                    <label>
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                      >
                        Enter Device Name:
                      </span>
                      <input
                        className={classes.textField}
                        type="text"
                        {...bindAddDeviceName}
                        name="addDeviceName"
                      />
                    </label>
                  </div>

                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#00C99B",
                        color: "#fff",
                        marginTop: 15,
                        height: 30,
                        width: 110,
                      }}
                      variant="contained"
                      onClick={(evt) => connect(evt)}
                      margin="normal"
                    >
                      Connect
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#FF2E2E",
                        color: "#fff",
                        marginTop: 15,
                        height: 30,
                        width: 110,
                      }}
                      variant="contained"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </p>
                </div>
              </DialogContent>
            </Dialog>
      </div>

    </ClickAwayListener>
  );
};

export default DeviceListComponent;
