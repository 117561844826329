export const createDateMod = (period) => {
    return `&dt_1=${period[0]}&dt_2=${period[1]}`;
}

export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

export  function isValidPhone(phone) {
    let pattern = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    return phone.match(pattern)
}
