import React, { useEffect, useCallback } from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";

import Popper from "@material-ui/core/Popper";
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from "@material-ui/core/styles";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import SelectDateComponent from "./selectDateComponent";
import YourAccComponent from "./yourAccPopperComponent";
import RenameDeviceComponent from "./renameDeviceComponent";
import DeviceSettingComponent from "./deviceSettingComponent";
import AddDeviceGraphComponent from "./addDeviceGraphComponent";
import StartStopDeviceComponent from "./startStopDeviceComponent";
import DeviceListComponent from "./deviceListComponent";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/deviceActions/device.action";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },

    toolchik: {
      backgroundColor: 'white',
      color: 'red',
      borderWidth: 1,
      borderColor: 'red',
      fontSize: 14,
      maxWidth: 140,
  },

  menuItemBlock: {
    maxWidth: 198,
    width:'100%',
    backgroundColor: "#fff",
    [theme.breakpoints.down("xs")]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
    },

  },


  menuItemBlockListDev: {
    display: 'none',
    maxWidth: 198,
    width:'100%',
    backgroundColor: "#fff",
    [theme.breakpoints.down("xs")]: {
      display: 'block',

    },
    [theme.breakpoints.down("sm")]: {
      display: 'none',
    },
  },

  btnMenu: {
    width:'100%',
    height: 50,
    borderColor: "#2E5BFF",
    borderWidth: 2,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
  },
}));

const MenuItemComponent = (props) => {
  const { variantPopper } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openLint, setOpenLint] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const [start, setStart] = React.useState(false);
  const [stop, setStop] = React.useState(false);
  const multilineMode = useSelector(
    (state) => state.deviceReducer.multilineMode
  );
  const deviceList = useSelector((state) => state.deviceReducer.deviceList);
  const devicesMultilineList = useSelector(
    (state) => state.deviceReducer.devicesMultilineList
  );
  const startStopDevices = useSelector(
    (state) => state.deviceReducer.start_stop_devices
  );

  const startStopMode = useSelector(
    (state) => state.deviceReducer.start_stop_mode
  );

  const delete_dev_mode = useSelector(
    (state) => state.deviceReducer.delete_dev_mode
  );

  const setDevicesMultilineList = useCallback(
    (devicesMultilineList) =>
      dispatch(actions.setDevicesMultilineList(devicesMultilineList)),
    [dispatch]
  );

  const setMultilineMode = useCallback(
    (multilineMode) => dispatch(actions.setMultilineMode(multilineMode)),
    [dispatch]
  );

  const setStartStopMode = useCallback(
    (stStMode) => dispatch(actions.setStartStopMode(stStMode)),
    [dispatch]
  );
  const setStartStopDevices = useCallback(
    (device) => dispatch(actions.setStartStopDevices(device)),
    [dispatch]
  );

  const sortingResults = useCallback(
    (status) => dispatch(actions.setCurrentResults(status)),
    [dispatch]
  );

  const handleToggle = () => {

    setOpen((prevOpen) => !prevOpen);
  };
  const currentDevice = useSelector(
    (state) => state.deviceReducer.currentDevice
  );
  const multilineSample = useSelector(state => state.deviceReducer.multilineSample);
  const isLoad = useSelector(
    (state) => state.deviceReducer.isLoad
  );


  // console.log(currentDevice);
  const handleClose =useCallback( (event, display) => {

    if( display==='do') {
      return;
    }

    if(startStopMode&&startStopDevices&&display=='f'){
      return;
    }
    // if(startStopMode&&startStopDevices&&display!==false){
    //   return;
    // }
    if(multilineMode&&devicesMultilineList.length<3&&display===false){
      return;
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }, [startStopMode, startStopDevices, multilineMode, devicesMultilineList]);
  const disabledCond = useCallback(() => {
    // console.log(start, stop);

    return (
      (multilineMode &&
        variantPopper !== "YOUR_ACCOUNT" &&
        variantPopper !== "DEVICE_LIST" &&
        variantPopper !== "ADD_DEVICE_GRAPH" &&
        variantPopper !== "NORMAL_RESULTS" &&
        variantPopper !== "ALERT_RESULTS" &&
        variantPopper !== "ALL_RESULTS" &&
        variantPopper !== "SELECTED_RESULTS") ||
        (
          startStopMode &&
          variantPopper !== "DEVICE_LIST" &&
          variantPopper !== "NORMAL_RESULTS" &&
          variantPopper !== "ALERT_RESULTS" &&
          variantPopper !== "ALL_RESULTS" &&
          (start ? variantPopper !== "START_DEVICE" : true) &&
          (stop ? variantPopper !== "STOP_DEVICE" : true)
          // (closeOn === "start"?  variantPopper !== "START_DEVICE" : closeOn === "stop"?  variantPopper !== "STOP_DEVICE" : true)


          // && variantPopper !== "SELECTED_RESULTS"
        )
        ||
        (
          delete_dev_mode &&
          variantPopper !== "DEVICE_LIST" &&
          variantPopper !== "NORMAL_RESULTS" &&
          variantPopper !== "ALERT_RESULTS" &&
          variantPopper !== "ALL_RESULTS"
          // && variantPopper !== "SELECTED_RESULTS"
        ) ||
      (!currentDevice &&
        variantPopper !== "YOUR_ACCOUNT" &&
        variantPopper !== "DEVICE_LIST" &&
        variantPopper !== "ADD_DEVICE_GRAPH"&&
        variantPopper !== "NORMAL_RESULTS" &&
        variantPopper !== "ALERT_RESULTS" &&
        variantPopper !== "ALL_RESULTS" &&
        variantPopper !== "SELECTED_RESULTS") ||
        (!currentDevice && !multilineMode &&
          variantPopper !== "YOUR_ACCOUNT" &&
          variantPopper !== "DEVICE_LIST" &&
          variantPopper !== "ADD_DEVICE_GRAPH") ||
          (!deviceList?.length && variantPopper !== "YOUR_ACCOUNT" ) ||
      (variantPopper === "DEVICE_SETTING" &&
        currentDevice &&
        currentDevice.sentens.status_avg &&
        !currentDevice?.sentens?.status_backlight &&
        !currentDevice.sentens.tits_mail &&
        !currentDevice.sentens.status_scrsaver &&
        !currentDevice.sentens.status_relay &&
        !currentDevice.sentens.tel)
    );
  }, [variantPopper, multilineMode,startStopMode,currentDevice,deviceList,delete_dev_mode]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }




  const clickSwitch = () => {


    if (variantPopper === "START_DEVICE") {

      if(!startStopMode){
        debugger
        setStart(true)
        handleToggle();
      }

      return
    }

    if (variantPopper === "STOP_DEVICE") {
      debugger
      if(!startStopMode){

        setStop(true)
        handleToggle();
      }

      return
    }


    if (variantPopper === "DEVICE_LIST") {
      setOpenLint(!openLint);
      return;
    }
    if (variantPopper === "NORMAL_RESULTS") {
      sortingResults("normal");
      return;
    }
    if (variantPopper === "ALL_RESULTS") {
      sortingResults("all");
      return;
    }
    if (variantPopper === "ALERT_RESULTS") {
      sortingResults("alert");
      return;
    }
    handleToggle();
    return;
  };
  const currentResults = useSelector(
    (state) => state.deviceReducer.currentResults
  );
  const renderCondition = () =>
    open ||
    openLint ||
    (variantPopper === "NORMAL_RESULTS" && currentResults === "normal") ||
    (variantPopper === "ALL_RESULTS" && currentResults === "all") ||
    (variantPopper === "ALERT_RESULTS" && currentResults === "alert");
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {


    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  React.useEffect(() => {

    if (variantPopper === "ADD_DEVICE_GRAPH" && open === true) {
      if(!multilineMode){

         setMultilineMode(true);
      }
    }

    if (variantPopper === "START_DEVICE"&&open === true) {

      if(!startStopMode){
            setStartStopDevices(currentDevice)
            setStartStopMode(true);
      }

      return;
    }

    if (variantPopper === "STOP_DEVICE"&&open === true) {

      if(!startStopMode){
            setStartStopDevices(currentDevice)
            setStartStopMode(true);
      }

      return;
    }
  }, [open]);

  return (
    <div
    style={{position: 'relative'}}
    className={variantPopper==="DL" ? classes.menuItemBlockListDev: classes.menuItemBlock}>
      <Tooltip variant={"outlined"} title={disabledCond()?"Disable the current mode to use this function": ""} classes={{tooltip: classes.toolchik}} >
        <span>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={() => clickSwitch()}
        variant={renderCondition() ? "contained" : "outlined"}
        className={classes.btnMenu}
        disabled={
          disabledCond() ?
          true
          : false
        }
        style={
          renderCondition()
            ? { backgroundColor: "#FF2E2E", color: "#fff" }
            : (variantPopper === "SELECTED_RESULTS" ||
                variantPopper === "ALL_RESULTS" ||
                variantPopper === "ALERT_RESULTS" ||
                variantPopper === "NORMAL_RESULTS") &&
              currentDevice
            ? { color: "#2E5BFF" }
            : {}
        }
      >
        {props.name ?? ""}
        {variantPopper !== "NORMAL_RESULTS" &&
        variantPopper !== "ALERT_RESULTS" &&
        variantPopper !== "ALL_RESULTS" ? (
          open || openLint ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )
        ) : null}
      </Button>
      </span>
      </Tooltip>
      {
        variantPopper === "DEVICE_LIST" ?
        <div style={{
          position:"absolute",
          zIndex: 60,
          top:50,
          left: 2,
          width: '100%',

          }}>
                 <DeviceListComponent
                        handleClose={handleClose}
                        deviceList={props.deviceList ? props.deviceList : []}
                        openLint={openLint}

                        // currDevForMult={currentDevice}
                      />
        </div> :
              <Popper
              open={variantPopper === "DEVICE_LIST" ? true : open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              placement={
                variantPopper === "DEVICE_SETTING" ? "bottom-start" : "bottom-end"
              }
              style={{ zIndex: 60, }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "right top" : "right bottom",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    {variantPopper === "SELECTED_RESULTS" ? (
                      <SelectDateComponent handleClose={handleClose} />
                    ) : variantPopper === "ALL_RESULTS" ? (
                      <Typography></Typography>
                    ) : variantPopper === "ALERT_RESULTS" ? (
                      <Typography></Typography>
                    ) : variantPopper === "YOUR_ACCOUNT" ? (
                      <YourAccComponent handleClose={handleClose} />
                    ) : variantPopper === "RENAME_DEVICE" ? (
                      <RenameDeviceComponent handleClose={handleClose} />
                    ) : variantPopper === "DEVICE_SETTING" ? (
                      <DeviceSettingComponent handleClose={handleClose} />
                    ) : variantPopper === "ADD_DEVICE_GRAPH" ? (
                      <AddDeviceGraphComponent handleClose={handleClose} />
                    ) : variantPopper === "STOP_DEVICE" ? (
                      <StartStopDeviceComponent
                        sat={setStart}
                        sop={setStop}
                        value={"stop"}
                        styleValue={{ color: "#FF2E2E" }}
                        handleClose={handleClose}
                      />
                    ) : variantPopper === "START_DEVICE" ? (
                      <StartStopDeviceComponent
                      sat={setStart}
                      sop={setStop}
                        value={"start"}
                        styleValue={{ color: "#00C99B" }}
                        handleClose={handleClose}
                      />
                    )
                    : variantPopper === "DEVICE_LIST" ? (
                      <DeviceListComponent
                        handleClose={handleClose}
                        deviceList={props.deviceList ? props.deviceList : []}
                        openLint={openLint}

                        // currDevForMult={currentDevice}
                      />
                    ) : (
                      <Typography></Typography>
                    )}
                  </ClickAwayListener>
                </Grow>
              )}
            </Popper>
      }

    </div>
  );
};

export default MenuItemComponent;
