
export const DEVICES_LIST = 'DEVICES_LIST';
export const CURRENT_DEVICE = 'CURRENT_DEVICE';
export const CURRENT_DEVICE_INFO = 'CURRENT_DEVICE_INFO';
export const CURRENT_PERIOD = 'CURRENT_PERIOD';
export const CURRENT_DEVICE_ARR = 'CURRENT_DEVICE_ARR';
export const CURRENT_RESULTS = 'CURRENT_RESULTS';
export const CURR_MAX_SIZE = 'CURR_MAX_SIZE';
export const RANGE = 'RANGE';
export const CURRENT_USER_INFO = 'CURRENT_USER_INFO';
export const RENAME = 'RENAME';

export const PHONES_AND_MAILS = 'PHONES_AND_MAILS';

export const MULTILINE_MODE = 'MULTILINE_MODE';

export const DEVICES_MULTILINE = 'DEVICES_MULTILINE';

export const MULTILINE_MAXSIZE = 'MULTILINE_MAXSIZE';

export const MULTILINE_SAMPLE = 'MULTILINE_SAMPLE';

export const IS_LOAD ='IS_LOAD';

export const NOT_DISABLE ='NOT_DISABLE';
export const FULL_LIST ='FULL_LIST';
export const START_STOP_MODE ='START_STOP_MODE';
export const START_STOP_DEVICES ='START_STOP_DEVICES';
export const IS_SELECTED_PERIOD_SAMPLE ='IS_SELECTED_PERIOD_SAMPLE';
export const DELETE_DEV_MODE ='DELETE_DEV_MODE';
export const DELETE_DEV ='DELETE_DEV';