import React, { useEffect, useCallback } from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Paper from "@material-ui/core/Paper";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   // KeyboardTimePicker,
//   // KeyboardDatePicker,
//   // KeyboardDateTimePicker,
// } from "@material-ui/pickers";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import * as actions from "../../redux/actions/deviceActions/device.action";
import { DateTimePicker } from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { format } from "date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useStaticState, ClockView, Calendar } from "@material-ui/pickers";
import { PickersClockView, PickersCalendarView } from "@material-ui/pickers";
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
          {/* <Typography></Typography> */}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },

  menuItemBlock: {
    maxWidth: 198,
  },

  btnMenu: {
    width: 198,
    borderColor: "#2E5BFF",
    borderWidth: 2,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
  },
}));

const SelectDateComponent = (props) => {
  // const setIsLoad = useCallback(
  //   (isLoad) =>
  //     dispatch(actions.setIsLoad(isLoad)),
  //   [dispatch]
  // );
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  const devicesMultilineList = useSelector(
    (state) => state.deviceReducer.devicesMultilineList
  );

  const sampleMultilinesForDate = useCallback(
    (deviceList, dateModificators) =>
      dispatch(actions.sampleMultilines(deviceList, dateModificators)),
    [dispatch]
  );

  const currentDevice = useSelector(
    (state) => state.deviceReducer.currentDevice
  );

  const multilineMode = useSelector(
    (state) => state.deviceReducer.multilineMode
  );

  const getInfoForSelectedDate = useCallback(
    (devId, dateModificators) =>
      dispatch(actions.getDeviceInfo(devId, dateModificators)),
    [dispatch]
  );

  const currentPeriod = useSelector(
    (state) => state.deviceReducer.currentPeriod
  );

  const [selectedDateFrom, setSelectedDateFrom] = React.useState(
    currentPeriod[1] ?? new Date()
  );

  const [sDFP, setSDFP] = React.useState(currentPeriod[0] ?? new Date());
  const [sDTP, setSDTP] = React.useState(currentPeriod[0] ?? new Date());

  const [selectedDateFromView, setSelectedDateFromView] = React.useState(
    moment(currentPeriod[1]).format("YYYY-MM-DD hh:mm:ss a") ??
      moment(new Date()).format("YYYY-MM-DD hh:mm:ss a")
  );

  const [selectedTimeFrom, setSelectedTimeFrom] = React.useState(
    currentPeriod[1] ?? new Date()
  );

  const [selectedDateTo, setSelectedDateTo] = React.useState(
    currentPeriod[0] ?? new Date()
  );

  const [selectedDateToView, setSelectedDateToView] = React.useState(
    moment(currentPeriod[0]).format("YYYY-MM-DD hh:mm:ss a") ??
      moment(new Date()).format("YYYY-MM-DD hh:mm:ss a")
  );
  const [EDO, setEDO] = React.useState(false);
  const [openTo, setOpenTo] = React.useState(false);
  const [openFrom, setOpenFrom] = React.useState(false);
  const [mh, setMH] = React.useState("h");
  const [selectedTimeTo, setSelectedTimeTo] = React.useState(
    moment(currentPeriod[1]).format("hh:mm") ?? new Date()
  );
  // moment(currentPeriod[0]).format("YYYY-MM-DD HH:mm:ss")
  const [dateTo, handleDateChangeTo] = React.useState(currentPeriod[0]);
  const [dateFrom, handleDateChangeFrom] = React.useState(currentPeriod[1]);
  const [calendarView, setCalendarView] = React.useState('date');

  // const { pickerProps, wrapperProps } = useStaticState({
  //   value: openTo ? selectedDateTo :  selectedDateFrom,
  //   onChange: openTo ?  setSelectedDateTo : setSelectedDateFrom,
  // });
  // console.log('adadadadadadadadadaddad', selectedDateTo);

  const calViewChange = (date) => {
    
    setCalendarView(date);
  }

  useEffect(()=>{

  },[calendarView])

  const save = (evt) => {
    //let dateMod = `&dt_1=${selectedDateTo}&dt_2=${selectedDateFrom}`;
    const MomentDateTo = moment(selectedDateTo, 'YYYY-MM-DD hh:mm:ss a');
    const MomentDateFrom = moment(selectedDateFrom, 'YYYY-MM-DD hh:mm:ss a');

    let dateMod = `&dt_1=${MomentDateTo.toISOString()}&dt_2=${MomentDateFrom.toISOString()}`;

    if (devicesMultilineList?.length && multilineMode) {
      sampleMultilinesForDate(devicesMultilineList, dateMod);
      props.handleClose(evt);
      return;
    }
    getInfoForSelectedDate(currentDevice.device_key, dateMod);
    props.handleClose(evt);
  };
  const onChangeDateTo = (date, c) => {
    ;
    if (!c) {
      if (mh === "h") {
        setMH("m");
      }

      if (mh === "m") {
        setMH("h");
        // setEDO(true);
        // if(openTo) {setOpenTo(false)} else{ setOpenFrom(false)}
      }
    }
    handleDateChangeTo(date);
    setSelectedDateTo(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    setSelectedDateToView(moment(date).format("YYYY-MM-DD hh:mm:ss a"));
  };

  const onChangeDateFrom = (date, c) => {
    ;
    // if (!c) {
    //   if (mh === "h") {
    //     setMH("m");
    //   }

    //   if (mh === "m") {
    //     setMH("h");
    //     // setEDO(true);

    //     // if(openTo) {setOpenTo(false)} else{ setOpenFrom(false)}
    //   }
    // }
    handleDateChangeFrom(date);
    let a = moment(date).format("YYYY-MM-DD hh:mm:ss");
    // 
    setSelectedDateFrom(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    setSelectedDateFromView(moment(date).format("YYYY-MM-DD hh:mm:ss a"));
  };

  useEffect(() => {
    if (EDO) {
      setTimeout(() => setEDO(false), 5000);
    }
  }, [EDO]);

  // const [calendarView, setCalendarView] = React.useState("date");
  // console.log(currentPeriod);
  return (
    <Paper style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
      <ClickAwayListener
        onClickAway={
          openTo || openFrom || EDO
            ? (e) => props.handleClose(e, "do")
            : (e) => props.handleClose(e)
        }
      >
        <div
          style={{
            width: 230,
            textAlign: "center",
            boxShadow: "-3px 3px 10px 4px rgba(221, 221, 221, 1)",
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 5,
          }}
        >
          {/* <Typography
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "#FF2E2E",
              textAlign: "center",
            }}
          >
            To view detection results during a specific period of time, select
            below the date and time <b style={{ color: "#2E5BFF" }}>From</b> to{" "}
            <b style={{ color: "#2E5BFF" }}>End</b>
          </Typography> */}

          <Typography variant="h6" style={{ color: "#2E5BFF" }}>
            FROM
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: 5,
            }}
          >
            <DateTimePicker
              onOpen={() => setOpenFrom(true)}
              renderInput={(props) => <TextField {...props} helperText="" />}
              value={dateFrom}
              onChange={onChangeDateFrom}
              // PopperProps={{ style: { display: "none" } }}
              // disableCloseOnSelect={true}
              onError={console.log}
              inputVariant="outlined"
              size="small"
              format="MM/dd/yyyy hh:mm a"
              disableFuture
              autoOk
              inputProps={{
                style: { fontSize: 12, display: "flex", textAlign: "center" },
              }}
            />
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                value={selectedDateFrom}
                onChange={onChangeDateFrom}
                onError={console.log}
                inputVariant="outlined"
                size="small"
                format="MM/dd/yyyy hh:mm a"
                disableFuture
                autoOk
                inputProps={{
                  style: { fontSize: 12, display: "flex", textAlign: "center" },
                }}
              />
            </MuiPickersUtilsProvider> */}
          </div>
          <Typography variant="h6" style={{ color: "#2E5BFF", marginTop: 15 }}>
            UNTIL
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: 5,
            }}
          >
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                value={selectedDateTo}
                onChange={onChangeDateTo}
                onError={console.log}
                inputVariant="outlined"
                size="small"
                format="MM/dd/yyyy hh:mm a"
                disableFuture
                // autoOk
                inputProps={{
                  style: { fontSize: 12, display: "flex", textAlign: "center" },
                }}
                // ampm={false}
              />
            </MuiPickersUtilsProvider> */}
            <DateTimePicker
              onOpen={() => setOpenTo(true)}
              renderInput={(props) => <TextField {...props} helperText="" />}
              value={dateTo}
              onChange={onChangeDateTo}
              // disableCloseOnSelect={true}
              // PopperProps={{ style: { display: "none" } }}
              onError={console.log}
              inputVariant="outlined"
              size="small"
              format="MM/dd/yyyy hh:mm a"
              disableFuture
              autoOk
              inputProps={{
                style: { fontSize: 12, display: "flex", textAlign: "center" },
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            <Button
              style={{
                backgroundColor: "#00C99B",
                color: "#fff",
                marginTop: 15,
                height: 30,
                width: 110,
              }}
              variant="contained"
              onClick={(evt) => save(evt)}
              margin="normal"
            >
              Save
            </Button>
            <Button
              style={{
                backgroundColor: "#B0BAC9",
                color: "#fff",
                marginTop: 15,
                height: 30,
                width: 110,
              }}
              variant="contained"
              onClick={props.handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </ClickAwayListener>

      {/* <Dialog
        onClose={() => {
          setEDO(true);
          if (openTo) {
            setSDTP(selectedDateToView);
          } else {
            setSDFP(selectedDateFromView);
          }
          calViewChange('date');
          openTo ? setOpenTo(false) : setOpenFrom(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openTo || openFrom}
      >
        <div
          style={{
            backgroundColor: "#f2f2f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" style={{ color: "#3f51b5", marginTop: 15 }}>
            Currently selected
          </Typography>
          <Typography variant="h5" style={{ color: "#3f51b5" }}>
            {openTo ? selectedDateToView : selectedDateFromView}

          </Typography>
        </div>
        <div
          style={{
            backgroundColor: "#3f51b5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" style={{ color: "#f2f2f2", marginTop: 15 }}>
            Update
          </Typography>
        </div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable force tabs example"
          >
            <Tab
              label="Date"
              wrapper={{ flexDirection: "column-reverse" }}
              icon={<DateRangeIcon />}
              {...a11yProps(0)}
            />
            <Tab label="Time" icon={<AccessTimeIcon />} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>

          <PickersCalendarView
            date={openTo ? dateTo : dateFrom}
            view={calendarView}
            views={["date", "year", 'month']}
            changeView={calViewChange}
            onChange={
              openTo
                ? (date) => onChangeDateTo(date, "cal")
                : (date) => onChangeDateFrom(date, "cal")
            }
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PickersClockView
            type={mh === "m" ? "minutes" : "hours"}
            date={openTo ? dateTo : dateFrom}
            // ampm={true}
            // ampmInClock={true}
            onChange={
              openTo
                ? (date) => onChangeDateTo(date)
                : (date) => onChangeDateFrom(date)
            }
            // TODO Fix me by generic component types
            {...{}}
          />
        </TabPanel>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            // marginTop: 15,
            marginBottom: 15,
          }}
        >
          <Button
            style={
              {
                // backgroundColor: "#00C99B",
                // color: "#fff",
                // marginTop: 15,
                // height: 30,
                // width: 110,
                color: "#3f51b5"
              }
            }
            // variant="contained"
            onClick={() => {
              if (openTo) {
                setSelectedDateTo(moment(sDTP).format("YYYY-MM-DD HH:mm:ss"));
                setSelectedDateToView(sDTP);
              } else {
                setSelectedDateFrom(moment(sDFP).format("YYYY-MM-DD HH:mm:ss"));
                setSelectedDateFromView(sDFP);
              }
              calViewChange('date');
              setEDO(true);
              openTo ? setOpenTo(false) : setOpenFrom(false);
            }}
            margin="normal"
          >
            Cancel
          </Button>
          <Button
            style={
              {
                // backgroundColor: "#B0BAC9",
                // color: "#fff",
                // marginTop: 15,
                // height: 30,
                // width: 110,
                color: "#3f51b5"
              }
            }
            // variant="contained"
            onClick={() => {
              if (openTo) {
                setSDTP(selectedDateToView);
              } else {
                setSDFP(selectedDateFromView);
              }
              setEDO(true);
              openTo ? setOpenTo(false) : setOpenFrom(false);
            }}
          >
            Ok
          </Button>
        </div>
      </Dialog> */}
    </Paper>
  );
};

export default SelectDateComponent;
