import io from "socket.io-client";
import {URL_API} from '../environment';
import { axios } from 'axios';
import cookie_js from 'cookie_js';
import Cookies from 'js-cookie'
import {is_local_build, test_user_key} from "../index";



export default  class DeviceService {
 static deviceList = () => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    // Cookies.get('user_key');

    return fetch(`${URL_API}/dev_api/dev_info.php?user_key=${userKey}&full`).then((data)=>data.json());
    // return     axios({
    //   method: 'get',
    //   url: `${URL_API}/dev_api/dev_info.php?user_key=bdbabe50374c9cf6c822b19cd3331252ca5fb927`,
    // });

  };

  static startCalibration = (dev_id) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_calibration&status=1&akk_key=${userKey}&user_key=${userKey}&dev_id=${dev_id}`).then((data)=>data.json());

  };

  static stopCalibration = ( dev_id) => {
    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_calibration&status=0&akk_key=${userKey}&user_key=${userKey}&dev_id=${dev_id}`).then((data)=>data.json());

  };

  static getPhonesAndMails = (upload_arr_alert) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_comand.php?type=upload_arr_alert&user_key=${userKey}`).then((data)=>data.json());

  };

  static addMailInAlert = (mail) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_user_mail&user_mail=${mail}&user_key=${userKey}`).then((data)=>data.json());

  };

  static addPhoneInAlert = (tel) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_user_tel&user_tel=${tel}&user_key=${userKey}`).then((data)=>data.json());

  };

  static removeAlert = (alert_id) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_comand.php?type=del_user_allert&allert_id=${alert_id}&user_key=${userKey}`).then((data)=>data.json());

  };

  static renameDevice = (name, dev_id) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_dev_name&dev_name=${name}&user_key=${userKey}&dev_id=${dev_id}`).then((data)=>data.json());

  };

  static addDevice = (dev_id) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_activate.php?user_key=${userKey}&dev_id=${dev_id}&pass=1111`).then((data)=>data.json());

  };

  static startDevice = (dev_id) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(    `${URL_API}/dev_api/dev_comand.php?type=set_powerenger&status=1&akk_key=${userKey}&user_key=${userKey}&dev_id=${dev_id}`).then((data)=>data.json());

  };

  static stopDevice = ( dev_id) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_powerenger&status=0&akk_key=${userKey}&user_key=${userKey}&dev_id=${dev_id}`).then((data)=>data.json());

  };

  static deviceInfoCalc = (devId, modificators) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_set_date.php?user_key=${userKey}&dev_id=${devId}&type=now&calc${modificators ? modificators : '' }`).then((data)=>data.json());
  }

  static deviceInfo = (devId, range, dateModificators) => {
    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch(`${URL_API}/dev_api/dev_set_date.php?user_key=${userKey}&dev_id=${devId}&type=now&fr_1=${range[0]}&fr_2=${range[1]}${dateModificators ? dateModificators : ''}`).then((data)=>data.json());
  }

  static saveDeviceSetting = (body, devId) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch( `${URL_API}/dev_api/dev_comand.php?type=set_dev_sentens&body_comand=${body}&akk_key=${userKey}&user_key=${userKey}&dev_id=${devId}`).then((data)=>data.json());
  }

  static deleteDevice = (devId) => {

    let userKey = is_local_build ?  test_user_key : Cookies.get('user_key');
    return fetch( `${URL_API}/dev_api/dev_comand.php?type=undo_dev&user_key=${userKey}&dev_id=${devId}`).then((data)=>data.json());
  }



  // static deviceList = () => {

  //   let userKey =  Cookies.get('user_key');

  //   return fetch(`${URL_API}/dev_api/dev_info.php?user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&full`).then((data)=>data.json());
  //   // return     axios({
  //   //   method: 'get',
  //   //   url: `${URL_API}/dev_api/dev_info.php?user_key=bdbabe50374c9cf6c822b19cd3331252ca5fb927`,
  //   // });

  // };

  // static getPhonesAndMails = (upload_arr_alert) => {

  //   let userKey =  Cookies.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_comand.php?type=upload_arr_alert&user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b`).then((data)=>data.json());

  // };

  // static addMailInAlert = (mail) => {

  //   let userKey =  Cookies.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_user_mail&user_mail=${mail}&user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b`).then((data)=>data.json());

  // };

  // static addPhoneInAlert = (tel) => {

  //   let userKey =  Cookies.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_user_tel&user_tel=${tel}&user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b`).then((data)=>data.json());

  // };

  // static removeAlert = (alert_id) => {

  //   let userKey =  Cookies.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_comand.php?type=del_user_allert&allert_id=${alert_id}&user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b`).then((data)=>data.json());

  // };

  // static renameDevice = (name, dev_id) => {

  //   let userKey =  Cookies.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_dev_name&dev_name=${name}&user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&dev_id=${dev_id}`).then((data)=>data.json());

  // };

  // static addDevice = (dev_id) => {

  //   let userKey =  Cookies.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_activate.php?user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&dev_id=${dev_id}&pass=1111`).then((data)=>data.json());

  // };

  // static startDevice = (dev_id) => {

  //   let userKey =  Cookies.get('user_key');
  //   return fetch(    `${URL_API}/dev_api/dev_comand.php?type=set_powerenger&status=1&akk_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&dev_id=${dev_id}`).then((data)=>data.json());

  // };

  // static stopDevice = ( dev_id) => {

  //   let userKey =  Cookies.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_comand.php?type=set_powerenger&status=0&akk_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&dev_id=${dev_id}`).then((data)=>data.json());

  // };

  // static deviceInfoCalc = (devId, modificators) => {

  //   let userKey = cookie_js.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_set_date.php?user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&dev_id=${devId}&type=now&calc${modificators ? modificators : '' }`).then((data)=>data.json());
  // }

  // static deviceInfo = (devId, range, dateModificators) => {
  //   let userKey = cookie_js.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_set_date.php?user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&dev_id=${devId}&type=now&fr_1=${range[0]}&fr_2=${range[1]}${dateModificators ? dateModificators : ''}`).then((data)=>data.json());
  // }

  // static deviceInfoAll = (devId, range, dateModificators) => {
  //   let userKey = cookie_js.get('user_key');
  //   return fetch(`${URL_API}/dev_api/dev_set_date.php?user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&dev_id=${devId}&type=now${dateModificators ? dateModificators : ''}`).then((data)=>data.json());
  // }

  // static saveDeviceSetting = (body, devId) => {
  //   let userKey = cookie_js.get('user_key');
  //   return fetch( `${URL_API}/dev_api/dev_comand.php?type=set_dev_sentens&body_comand=${body}&akk_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&dev_id=${devId}`).then((data)=>data.json());
  // }

  // static deleteDevice = (devId) => {

  //   let userKey = cookie_js.get('user_key');
  //   return fetch( `${URL_API}/dev_api/dev_comand.php?type=undo_dev&user_key=8c08a3778daec1eeedc478aeff1daf9c3c95714b&dev_id=${devId}`).then((data)=>data.json());
  // }
}
