import React, {useCallback, useState} from "react";
import {Hidden, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import {useDispatch, useSelector} from "react-redux";

import DeviceService from "../../services/device.service";
import CalibResultComponent from "./calibResultComponent";


const CalibrateComponent = (props) => {

    const [open_calibrate_device_modal, set_open_calibrate_device_modal] = useState(false)
    const [open_calib_result_modal, set_open_calib_result_modal] = useState(false)
    const currentDevice = useSelector(
        (state) => state.deviceReducer.currentDevice
    );

    const handleClose =useCallback( () => {
        set_open_calib_result_modal(false);
    }, []);

    return (
        <>
            <div

                onClick={() => {
                    set_open_calibrate_device_modal(true);
                }}
                style={{
                    padding: 12,
                    border: '1px solid #00C99B',
                    width: "100%",
                    boxSizing: 'border-box',
                    marginTop: 5,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                    height: '46px',
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                <Typography
                    style={{
                        fontSize: 14,
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                    }}
                >
                    Calibrate
                </Typography>
            </div>
            <Dialog
                open={open_calibrate_device_modal}
                onClose={() => set_open_calibrate_device_modal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    style={{color: "#0F34BB", textAlign: "center"}}
                >
                    {"Calibrate device"}
                </DialogTitle>
                <DialogContent>
                    <div style={{marginBottom: 24}}>
                        <span style={{fontSize: 20}}>Device for calibrate:{' '}
                            <b>  {currentDevice?.name}</b>

                        </span>
                    </div>
                    <div style={{
                        marginBottom: 8,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                        <Button
                            style={{
                                backgroundColor: "#2E5BFF",
                                color: "#fff",
                                marginTop: 5,
                                height: 30,
                                width: 79,

                            }}
                            variant="contained"
                            onClick={
                                (evt) => {
                                    evt.preventDefault()
                                    DeviceService.startCalibration(currentDevice.device_key).then((res) => {
                                        if (res.code < 300) {
                                            set_open_calibrate_device_modal(false);
                                            set_open_calib_result_modal(true);
                                        }
                                    })
                                }
                            }
                            margin="normal"
                        >
                            Start
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#FF2E2E",
                                color: "#fff",
                                marginTop: 5,
                                height: 30,
                                width: 79,
                            }}
                            variant="contained"
                            onClick={(e) => {
                                e.preventDefault()
                                DeviceService.stopCalibration(currentDevice.device_key).then((res) => {
                                    if (res.code < 300) {
                                        set_open_calibrate_device_modal(false)
                                    }
                                })
                            }
                            }
                        >
                            Stop
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#888",
                                color: "#fff",
                                marginTop: 5,
                                height: 30,
                                width: 79,

                            }}
                            variant="contained"
                            onClick={
                                (evt) => {
                                    evt.preventDefault();
                                    set_open_calibrate_device_modal(false);
                                    set_open_calib_result_modal(true);
                                }
                            }
                            margin="normal"
                        >
                            Result
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <CalibResultComponent
                open={open_calib_result_modal}
                handleClose={handleClose}
            >
            </CalibResultComponent>
        </>
    )
}

export default CalibrateComponent
