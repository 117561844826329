import React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import Home from './components/home';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import {isAuthGuard} from './guards/isAuthGuard';
import Loading from './components/loading';
import NotFound from './components/error';
import MainPage from './components/mainPage/mainPage';

const AppRouter = () => 
(
  <GuardProvider guards={[isAuthGuard]}  loading={Loading} error={NotFound}>
        <Switch >
          {/* <GuardedRoute path="/" exact component={Home}  />    */} 
          <GuardedRoute path="/" exact component={MainPage}  meta={{ auth: true }}/>
        </Switch>
  </GuardProvider>
);

export default AppRouter;



