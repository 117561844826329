import React, { useState, useEffect, useCallback } from "react";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    LineChart,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Brush, Legend
} from "recharts";
import LoadingOverlay from "react-loading-overlay";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import momentTimeZ from "moment-timezone";
import * as actions from "../../redux/actions/deviceActions/device.action";

const getLegendOptions = (dataKey) => {
    switch (dataKey) {
        case 'ph':
            return {label: 'PH', color: "#9e10c2"}
        case 'conductivity':
            return {label: 'Conductivity', color: "#00ffe5"}
        case 'tds':
            return {label: 'TDS', color: "#79a5d3"}
        case 'dirty':
            return {label: 'Detected Level', color: "#007e57"}
    }

}


const getTooltipLabel = (dataKey) => {
  switch (dataKey) {
      case 'ph':
          return 'PH'
      case 'conductivity':
          return 'Conductivity'
      case 'tds':
          return 'TDS'
      case 'dirty':
          return 'Detected Level'
  }

}


const StyledToolTip = (props) => {
  const { active } = props;
    // console.log('props ', props)

  if (active) {
    const { payload, label } = props;
    return payload ? (
      <Paper className="custom-tooltip" style={{padding: 2, }}>
        <p style={{ color: "black" }} className="label">
          {payload[0]?.payload.deviceName ? payload[0].payload.deviceName : ""}
        </p>
          {
              payload.map(el=>{
                  const isDirty =  el.dataKey==='dirty';
                  const isRedline =  el.dataKey==='redLine';
                  return isRedline ? null : (
                      <p
                          className="label"
                          style={
                              isDirty && el?.value && el.value > 1
                                  ? { color: "red", margin:0, padding: 0 }
                                  : { color: el.stroke, margin:0, padding: 0 }
                          }
                      >
                          {!isNaN(el?.value)&&Number(el?.value)>=0  ?
                              `${getTooltipLabel(el?.dataKey)}: ${el.value}` : ""}
                      </p>
                  )
              })
          }
        {/*  <p*/}
        {/*      className="label"*/}
        {/*      style={*/}
        {/*          payload[0]?.value && payload[0].value > 1*/}
        {/*              ? { color: "red", margin:0, padding: 0 }*/}
        {/*              : { color: payload[0]?.stroke, margin:0, padding: 0 }*/}
        {/*      }*/}
        {/*  >*/}
        {/*      {getTooltipLabel(payload[0]?.dataKey)}{': '}{payload[0]?.value ? payload[0].value : ""}*/}
        {/*  </p>*/}

        {/*<p*/}
        {/*    className="label"*/}
        {/*    style={*/}

        {/*      { color: "#79a5d3" }*/}
        {/*    }*/}
        {/*>*/}
        {/*  {getTooltipLabel(payload[1]?.dataKey)}{': '} {payload[1]?.value ? payload[1].value : ""}*/}
        {/*</p>*/}


        {/*  <p*/}
        {/*      className="label"*/}
        {/*      style={*/}

        {/*      { color: "#9e10c2" }*/}

        {/*      }*/}
        {/*  >*/}
        {/*      {getTooltipLabel(payload[2]?.dataKey)}{': '}  {payload[2]?.value&&Number(payload[2]?.value)>=0 ? payload[2].value : ""}*/}
        {/*  </p>*/}
        {/*  <p*/}
        {/*      className="label"*/}
        {/*      style={*/}

        {/*          { color: "#9e10c2" }*/}

        {/*      }*/}
        {/*  >*/}
        {/*      {getTooltipLabel(payload[3]?.dataKey)}{': '}  {payload[3]?.value&&Number(payload[3]?.value)>=0 ? payload[3].value : ""}*/}
        {/*  </p>*/}
        <p style={{ color: "blue" }} className="label">
          {payload[0]?.payload.date_cr
            ? moment(payload[0].payload.date_cr).format("DD.MM.YYYY HH:mm")
            : ""}
        </p>
        {/* <p className="label">{payload[0]?.value ? payload[0].value : ''}</p> */}
      </Paper>
    ) : (
      ""
    );
  }

  return null;
};

const CustomizedDot = (props) => {
  ;
  const { cx, cy, stroke, payload, value } = props;
  if(!cy){
    return null;
  }

  // console.log(props);
  if (payload.rhomb) {
    return (
<svg viewBox="0 0 13 14" x={cx - 7.5} y={cy - 7.5} width={15} height={15} fill={stroke}><path  d="M5.9,1.2L0.7,6.5l5.2,5.4l5.2-5.4L5.9,1.2z" /></svg>
    );
  } else{
    return (
      <svg x={cx - 50} y={cy - 50} width={100} height={100}>
        <circle
          cx={50}
          cy={50}
          r="3"
          stroke={stroke}
          strokeWidth={1}
          fill={"white"}
        />
      </svg>
    );
  }

  return null;
};

const roundNumber = (num) => {
  return Number.isInteger(num) ? num : parseFloat(num.toFixed(2))
}

const LineChartComponent = (props) => {
    const [activeLine, setActiveLine] = useState(null);

    const handleMouseEnterLine = (dataKey) => {
        setActiveLine(dataKey);
    };

    const handleMouseLeaveLine = () => {
        setActiveLine(null);
    };

  const dispatch = useDispatch();
  const deviceInfoArr = useSelector(
    (state) => state.deviceReducer.currDeviceArr
  );
  const currentResults = useSelector(
    (state) => state.deviceReducer.currentResults
  );
  const multilineMode = useSelector(
    (state) => state.deviceReducer.multilineMode
  );

  const [list, setList] = React.useState([]);

 const formatted_list =  React.useMemo(()=>{
     let arrWithNames = [];
     const list_ = list.map(item =>{
         let newObj = {};

         Object.keys(item).forEach(key=>{
             if (!arrWithNames.find(item=>item===key)&&!isNaN(item[key])) {
                 if (key==='ph'&&item[key]<0) {
                     return;
                 }
                 arrWithNames.push(key)
             }
             if (isNaN(item[key])) {
                 newObj[key] = item[key];
                 return
             }
             newObj[key] =roundNumber(Number(item[key]))
         })
         return ({
         ...newObj,
         ph: newObj.ph < 0 ? undefined : newObj.ph,
         tds: newObj.tds < 0 ? undefined : newObj.tds,
         conductivity: newObj.tds < 0 ? undefined : roundNumber(newObj.tds/0.65)
     })
     });
const isHaveTds = list_.some(el=>el.tds!==undefined);
if (isHaveTds) {
    arrWithNames.push('conductivity')
} else {
    arrWithNames = arrWithNames.filter(item=>item!=='tds')
}
      return {
          linesNames: arrWithNames,
         isHaveTds,
          data:list_
      }

  }, [list]);



  const setIsLoad = useCallback(
    (isLoad) => dispatch(actions.setIsLoad(isLoad)),
    [dispatch]
  );

  const [dots, setDots] = React.useState({
    m1: false,
    m2: false,
    m3: false,
  });

  const range = useSelector((state) => state.deviceReducer.range);

  const fullList = useSelector((state) => state.deviceReducer.fullList);

  useEffect(() => {
    const newArr = [...deviceInfoArr];
    const newFullArr = [...fullList];

    const findDotPresence = prop => Boolean(deviceInfoArr.find(item => item[prop]));
    const findPropPresenceInSlice = (prop, start, end) => newFullArr.slice(start, end).find(item => item[prop]);

    setDots({
      m1: findDotPresence('m1'),
      m2: findDotPresence('m2'),
      m3: findDotPresence('m3'),
    });

    const processAbsorbUpdates = (propsToCheck, reverse=false) => {
      propsToCheck.forEach(prop => {
        const propValStart = findPropPresenceInSlice(prop, range[0] - 100, range[1] - 100);
        const propValEnd = findPropPresenceInSlice(prop, range[0] + 100, range[1] + 100);
        if(findDotPresence(prop) && propValEnd) {
          let t = (reverse ? newArr.reverse() : newArr).find((item) => item[prop]);
          let i = (reverse ? newArr.reverse() : newArr).findIndex((item) => item.realDate === t.realDate && item.absorb === t.absorb);
          newArr[i] = { ...t, [`${prop}p${reverse ? 'b' : ''}`]: t.absorb };
          newArr[reverse ? 0 : newArr.length - 1] = { ...newArr[reverse ? 0 : newArr.length - 1], [`${prop}p${reverse ? 'b' : ''}`]: propValEnd.absorb };
        }
      });
    }

    const mProps = ['m1', 'm2', 'm3'];

    mProps.forEach((mProp, index, array) => {
      const otherProps = array.filter(item => item !== mProp);
      if(!deviceInfoArr[deviceInfoArr.length - 1]?.[otherProps[0]] && !deviceInfoArr[deviceInfoArr.length - 1]?.[otherProps[1]]) {
        processAbsorbUpdates(otherProps);
      }
      if(!deviceInfoArr[0]?.[otherProps[0]] && !deviceInfoArr[0]?.[otherProps[1]]) {
        processAbsorbUpdates(otherProps, true);
      }
    });

    const filterMap = {
      'all': arr => arr,
      'normal': arr => arr.filter(item => item.absorb < 1),
      'alert': arr => arr.filter(item => item.absorb >= 1),
    };

    const filterFunc = filterMap[currentResults] || filterMap['all'];
    setList(filterFunc(newArr.reverse()));

    setIsLoad(false);
  }, [deviceInfoArr, currentResults, multilineMode]);

  return (
    <div
      style={{
        width: "100%",
        height: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column'
      }}
    >
<ResponsiveContainer
    width={'100%'}
    height={400}
>
      <LineChart
        // style={{ width: 1180 }}

        data={formatted_list.data}
        margin={{ top: 20, right: 20,  left: 20, bottom: 0 }}

      >
          {/*<Legend verticalAlign="bottom" />*/}
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis
            axisLine={{strokeWidth: 3}}
          dataKey="name"
        />
          {/*<YAxis scale="log"*/}
          {/*       domain={['auto', 'auto']} */}
          {/*       type="number"*/}
          {/*       tick={<CustomizedYAxisTick />} allowDataOverflow={true} />*/}

          <YAxis
            domain={[0, 1]}
            axisLine={{strokeWidth: 3}}
            orientation="left"
        />
          {formatted_list.isHaveTds ?
              <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{ value: 'Conductivity, TDS', angle: 90, position: 'right' }}
                  name="Series 2" domain={[0, 1]}
                  axisLine={{strokeWidth: 3}}/> : null}
        <Tooltip
            active={true}
            content={<StyledToolTip />}
            coordinate={{ x: 100, y: 140 }}
        />

        <Line
                    strokeWidth={3}
                    onMouseOver={()=>{
                        handleMouseEnterLine('dirty')
                    }}
                    onMouseOut={()=>{
                        handleMouseLeaveLine()
                    }}
          connectNulls
          type="monotone"
          dataKey="dirty"
          stroke="#007e57"
          activeDot={{ stroke: "red", strokeWidth: 5, r: 2 }}
        />

        <Line
                    strokeWidth={3}
          connectNulls
          type="monotone"
          dataKey="m1"
          stroke="#FF5900"
          dot={dots.m1 ? <CustomizedDot /> : false}
          activeDot={{ stroke: "red", strokeWidth: 5, r: 2 }}
        />

        <Line
            // data={formatted_list}
            strokeWidth={2}
            type="monotone"
            connectNulls
            dataKey="tds"
            yAxisId="right"
            stroke="#79a5d3"
            dot={<CustomizedDot />}
            activeDot={{ stroke: "red", strokeWidth: 5, r: 2 }}
        />

          <Line
              // data={formatted_list}
              strokeWidth={2}
              type="monotone"
              onMouseOver={()=>{
                  handleMouseEnterLine('dirty')
              }}
              onMouseOut={()=>{
                  handleMouseLeaveLine()
              }}
              connectNulls
              dataKey="conductivity"
              yAxisId="right"
              stroke="#00ffe5"
              dot={<CustomizedDot />}
              activeDot={{ stroke: "red", strokeWidth: 5, r: 2 }}
          />

          <Line
              // data={formatted_list}
              strokeWidth={2}
              type="monotone"
              dataKey="ph"
              connectNulls
              // yAxisId="right"
              stroke="#9e10c2"
              dot={<CustomizedDot />}
              activeDot={{ stroke: "red", strokeWidth: 5, r: 2 }}
          />
        <Line
                    strokeWidth={3}
          connectNulls
          type="monotone"
          dataKey="m2"
          stroke="#1067C8"
          dot={dots.m2? <CustomizedDot /> : false}
          activeDot={{ stroke: "red", strokeWidth: 5, r: 2 }}
        />
        <Line
                    strokeWidth={3}
          connectNulls
          type="monotone"
          dataKey="m3"
          stroke="#319677"
          dot={dots.m3 ? <CustomizedDot />: false}
          activeDot={{ stroke: "red", strokeWidth: 5, r: 2 }}
        />

        <Line
                    strokeWidth={3}
          connectNulls
          type="monotone"
          dataKey="m1p"
          stroke="#FF5900"
          dot={false}
          activeDot={false}
        />
        <Line
                    strokeWidth={3}
          connectNulls
          type="monotone"
          dataKey="m2p"
          stroke="#1067C8"
          dot={false}
          activeDot={false}
        />
        <Line
                    strokeWidth={3}
          connectNulls
          type="monotone"
          dataKey="m3p"
          stroke="#2a9a78"
          dot={false}
          activeDot={false}
        />

<Line
                    strokeWidth={3}
          connectNulls
          type="monotone"
          dataKey="m1pb"
          stroke="#FF5900"
          dot={false}
          activeDot={false}
        />
        <Line
                    strokeWidth={3}
          connectNulls
          type="monotone"
          dataKey="m2pb"
          stroke="#1067C8"
          dot={false}
          activeDot={false}
        />
        <Line
                    strokeWidth={3}
          connectNulls
          type="monotone"
          dataKey="m3pb"
          stroke="#06845d"
          dot={false}
          activeDot={false}
        />

          <ReferenceLine y={1} stroke="red" alwaysShow />

        {/*<Line*/}
        {/*  type="monotone"*/}
        {/*  dataKey="redLine"*/}
        {/*  stroke="red"*/}
        {/*  dot={false}*/}
        {/*  activeDot={false}*/}
        {/*/>*/}


      </LineChart>

</ResponsiveContainer>
        <div style={{display: 'flex'}}>
            {formatted_list.linesNames.map(item=>{
                const options = getLegendOptions(item)
                return options ? (
                    <div style={{display: 'flex', alignItems: 'center', margin: 20, }}>
                        <div>{options.label}</div>
                        <div style={{width: 10, height: 10, backgroundColor: options.color, marginLeft: 10}}></div>
                    </div>
                ) : null
            })}

        </div>
      <div style={{width: '100%', backgroundColor: '#f2f2f2', height: 20, display: 'flex', justifyContent: 'center', marginLeft: 30, marginRight: 30, marginBottom: 10, }}>
        <div style={deviceInfoArr.length===fullList.length|| props.lpDisable? {    width: 0,
      height: 0,
      borderTop: "10px solid transparent",
      borderRight: '20px solid #999',
      borderBottom: '10px solid transparent'}:{    width: 0,
      height: 0,
      borderTop: "10px solid transparent",
      borderRight: '20px solid #0f34bb',
      borderBottom: '10px solid transparent'}}></div>
        <div style={deviceInfoArr.length===fullList.length || props.lpDisable ?{  backgroundColor: '#999', display: 'inline', color: '#fff', paddingLeft: 40, paddingRight: 40}:{ cursor: 'pointer', backgroundColor: '#0f34bb', display: 'inline', color: '#fff', paddingLeft: 40, paddingRight: 40}} onClick={props.lp}>left</div>
        <div  style={deviceInfoArr.length===fullList.length|| props.rpDisable ?{  backgroundColor: '#999', display: 'inline', color: '#fff', paddingLeft: 39, paddingRight: 39, marginLeft: 5,}:{marginLeft: 5, cursor: 'pointer', backgroundColor: '#0f34bb', display: 'inline', color: '#fff', paddingLeft: 39, paddingRight: 39}} onClick={props.rp}>right</div>
        <div style={
        deviceInfoArr.length===fullList.length|| props.rpDisable? {width: 0,
          height: 0,
          borderTop: "10px solid transparent",
          borderLeft: '20px solid #999',
          borderBottom: '10px solid transparent'} : {width: 0,
      height: 0,
      borderTop: "10px solid transparent",
      borderLeft: '20px solid #0f34bb',
      borderBottom: '10px solid transparent'}}></div>
      </div>

    </div>
    // </LoadingOverlay>
  );
};
export default LineChartComponent;
