import * as actionTypes from '../../actions/deviceActions/actions.types'

const initialState = {
  currentDevice: null,
  deviceList: [],
  currDeviceInfo: null,
  isFetching: false,
  currMaxSize: 0,
  range: [null, null],
  currDeviceArr: [],
  currentPeriod: [], 
  currentResults: null,
  currentUserInfo: null,
  phonesAndMails: null,
  multilineMode: false,
  devicesMultilineList: [],
  displayMultiLines: false,
  multilineSample:new Map(),
  isLoad: false,
  multilineMaxSize: 0,
  notDisable: true,
  fullList: [],
  start_stop_mode: false,
  start_stop_devices: {},
  is_selected_period_sample: false,
  delete_dev_mode: false,
  devOnDel: {}
};

export default function deviceReducer(state = initialState, action) {
    
  switch (action.type) {

    case actionTypes.IS_LOAD:
      return {
        ...state,
        isLoad: action.isLoad,
      };

      case actionTypes.DELETE_DEV_MODE:
        return {
          ...state,
          delete_dev_mode: action.deleteDevModeStatus,
        };
        case actionTypes.DELETE_DEV:
          return {
            ...state,
            devOnDel: action.devOnDel,
          };

      case actionTypes.START_STOP_MODE:
        return {
          ...state,
          start_stop_mode: action.start_stop_mode,
        };

        case actionTypes.START_STOP_DEVICES:
          return {
            ...state,
            start_stop_devices: action.startStopDevices,
          };

          case actionTypes.IS_SELECTED_PERIOD_SAMPLE:
            return {
              ...state,
              is_selected_period_sample: action.isSelPer,
            };

      case actionTypes.FULL_LIST:
        return {
          ...state,
          fullList: action.fullList,
        };

          case actionTypes.NOT_DISABLE:
      return {
        ...state,
        notDisable: action.notDisable,
      };
    case actionTypes.DEVICES_LIST:
      return {
        ...state,
        deviceList: action.devicesList,
      };


      case actionTypes.MULTILINE_MAXSIZE:
        return {
          ...state,
          multilineMaxSize: action.multilineMaxSize,
        };

        
      case actionTypes.MULTILINE_SAMPLE:
        return {
          ...state,
          multilineSample: action.multilineSample,
        };


      case actionTypes.DEVICES_MULTILINE:
        return {
          ...state,
          devicesMultilineList: action.devicesMultilineList,
        };
  

        case actionTypes.MULTILINE_MODE:
          return {
            ...state,
            multilineMode: action.multilineMode,
          };
    case actionTypes.CURRENT_DEVICE:
      return {
        ...state,
        currentDevice: action.currentDevice,
      };

      case actionTypes.PHONES_AND_MAILS:
        return {
          ...state,
          phonesAndMails: action.phonesAndMails,
        };

      case actionTypes.CURRENT_RESULTS:
        return {
          ...state,
          currentResults: action.currentResults,
        };

      case actionTypes.CURRENT_DEVICE_INFO:
        return {
          ...state,
          currDeviceInfo: action.currentDeviceInfo,
        };  
        
      case actionTypes.CURRENT_PERIOD:
        return {
          ...state,
          currentPeriod: action.currentPeriod,
        };  

        case actionTypes.CURRENT_DEVICE_ARR:
          return {
            ...state,
            currDeviceArr: action.currDeviceArr,
          }; 

          case actionTypes.RENAME:
            return {
              ...state,
              currentDevice: {
                ...state.currentDevice,
                name: action.name,
              } 
            };   

            case actionTypes.CURRENT_USER_INFO:
              return {
                ...state,
                currentUserInfo: action.userInfo
              }; 
        case actionTypes.CURR_MAX_SIZE:
          return {
            ...state,
            currMaxSize: action.currMaxSize,
          };  
          case actionTypes.RANGE:
            return {
              ...state,
              range: action.range,
            };  
    default:
      return state;
  }
}