import React, { useEffect, useCallback } from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";


import * as actions from "../../redux/actions/deviceActions/device.action";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },

  menuItemBlock: {
    maxWidth: 198,
  },
  textField: {
    width: 168,
    height: 26,
    padding: 0,
    borderColor: "#EAF0F5",
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 5,
  },

  block: {
    width: "100%",
    marginTop: 5,
    backgroundColor: "#fff",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    flexDirection: 'column'
  },

  btn: {

    backgroundColor: "#2E5BFF",
    color: "#fff",
    marginTop: 5,
    height: 30,
    width: 79,
  },
}));

const AddDeviceGraphComponent = (props) => {

  const dispatch = useDispatch();
  const multilineMode = useSelector(
    (state) => state.deviceReducer.multilineMode
  );

  const notDisable = useSelector(
    (state) => state.deviceReducer.notDisable
  );

  const setIsLoad = useCallback(
    (isLoad) =>
      dispatch(actions.setIsLoad(isLoad)),
    [dispatch]
  );



  const setMultilineMode = useCallback(
    (multilineMode) => dispatch(actions.setMultilineMode(multilineMode)),
    [dispatch]
  );
  const  sampleMultilines = useCallback((deviceList) => dispatch(actions.sampleMultilines(deviceList)), [
    dispatch,
  ]);

  const display = (evt) => {
    let dateMod = `&dt_1=${moment().format("YYYY-MM-DD HH:mm:ss")}&dt_2=${moment().subtract(7,'d').format("YYYY-MM-DD HH:mm:ss")}`;
    if(devicesMultilineList?.length){
      setIsLoad(true);
      sampleMultilines(devicesMultilineList, dateMod);
    }
    props.handleClose(evt, true);
    
  }

  useEffect(()=> {
    if(!multilineMode){
      
    }
  }, [multilineMode])
  // const haClose = (e)=> {
  //   props.handleClose(e, true)
  // }

  const devicesMultilineList = useSelector(
    (state) => state.deviceReducer.devicesMultilineList
  );
    const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={(e)=>props.handleClose(e, false)}>
    <div
    style={{
      width: 198,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 6,
    }}
  >

    <Paper
      className={classes.block}
      style={{ display: "flex", paddingLeft: 7, boxSizing: 'border-box' }}
    >
        <Typography style={{fontSize: 14, fontWeight: 'normal'}}>To compare up to 3 devices simultaneously, click seperately on each selected device on the DeviceList.
To replace a device with another one from the device list, first click on the list on the device
you wish to remove, and than click on the replaced device.</Typography>


<div style={{                display: "flex",
                justifyContent: "space-between",
                width: "100%",}}>
          <Button
                className={classes.btn}
                disabled={notDisable}
                variant="contained"
                onClick={(evt)=> display(evt)}
                margin="normal"
              >
                Display
              </Button>
              <Button
                style={{
                  backgroundColor: "#FF2E2E",
                  color: "#fff",
                  marginTop: 5,
                  height: 30,
                  width: 79,
                  marginLeft: 10,
                  marginRight:10
                }}
                variant="contained"
                onClick={(evt)=>{   
                   setMultilineMode(false);
                   props.handleClose(evt, true); 
                  //  props.close(true)
                }
                }
              >
                Close
              </Button>
          </div>
    </Paper>

    </div>
    </ClickAwayListener>
  );
};

export default AddDeviceGraphComponent;
