import React, { useEffect, useCallback } from "react";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";

import { useInput } from "./../hooks/useInput";
import * as actions from "../../redux/actions/deviceActions/device.action";

import { useDispatch, useSelector } from "react-redux";
import { logo } from "./../../assets/images";
import { ToastProvider, useToasts } from 'react-toast-notifications';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },

  menuItemBlock: {
    maxWidth: 198,
  },
  textField: {
    width: 168,
    height: 26,
    padding: 0,
    borderColor: "#EAF0F5",
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 5,
  },

  block: {
    width: "100%",
    marginTop: 5,
    backgroundColor: "#fff",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },

  btnMenu: {
    width: 198,
    borderColor: "#2E5BFF",
    borderWidth: 2,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
  },
}));

const RenameDeviceComponent = (props) => {

  const dispatch = useDispatch();


  const  renameDev = useCallback((name, devId) => dispatch(actions.rename(name, devId)), [
    dispatch,
  ]);
  const { addToast } = useToasts()
  const saveNewName =(evt) => {
    if(newName.length > 40) {
      addToast('Name length error',  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })

      return;
    }
    if(newName==='') {
      
      props.handleClose(evt);
      return;
    }
    renameDev(newName, currentDevice.device_key)
    props.handleClose(evt);
  }
  const currentDevice = useSelector(state => state.deviceReducer.currentDevice);
    const classes = useStyles();
  const {
    value: currName,
    bind: bindCurrName,
    reset: resetCurrName,
  } = useInput(currentDevice.name ?? '');
  const {
    value: newName,
    bind: bindNewName,
    reset: resetNewName,
  } = useInput("");


  return (

    <div
    style={{
      width: 198,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 6,
    }}
  >
<ClickAwayListener onClickAway={props.handleClose}>
    <Paper
      className={classes.block}
      style={{ display: "flex", boxShadow: '-3px 3px 10px 4px rgba(221, 221, 221, 1)', }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <label style={{display: 'flex', flexDirection: 'column', marginTop: 15}}>
            <span
              style={{
                fontSize: 12,
                fontFamily: "Roboto",
                fontWeight: 500,
              }}
            >
              Device’s current name:
            </span>
            <input
              placeholder='Enter Name'
              className={classes.textField}
              style={{paddingLeft: 5}}
              type="text"
              value={currName}
              {...bindCurrName}
              name="currName"
              disabled={currentDevice?.name ? true : false}
            />
          </label>

          <label style={{display: 'flex', flexDirection: 'column', marginTop: 15}}>
            <span
              style={{
                fontSize: 12,
                fontFamily: "Roboto",
                fontWeight: 500,
              }}
            >
              Enter Device’s New name:
            </span>
            <input
              placeholder='Enter New Name'
              className={classes.textField}
              type="text"
              style={{paddingLeft: 5}}
              value={newName}
              {...bindNewName}
              name="newName"
            />
          </label>
        </form>

        <p
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            style={{
              backgroundColor: "#00C99B",
              color: "#fff",
              marginTop: 5,
              height: 30,
              width: 80,
            }}
            variant="contained"
            onClick={(evt) => saveNewName(evt)}
            margin="normal"
          >
            Confirm
          </Button>
          <Button
            style={{
              backgroundColor: "#B0BAC9",
              color: "#fff",
              marginTop: 5,
              height: 30,
              width: 80,
            }}
            variant="contained"
            onClick={props.handleClose}
          >
            Close
          </Button>
        </p>
      </div>
    </Paper>
    </ClickAwayListener>
    </div>

  );
};

export default RenameDeviceComponent;
