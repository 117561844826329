import React, { useEffect, useCallback } from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Paper from "@material-ui/core/Paper";
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { logo, rightPag, leftPag } from "./../../assets/images";
import MenuItemComponent from "./menuItem";
import TableComponent from "./tableComponent";
import LineChartComponent from "./lineChart";
import * as actions from "../../redux/actions/deviceActions/device.action";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import { createDateMod } from "./../../helpers/helpers";
import cookie_js from "cookie_js";
import { set } from "js-cookie";
import DeviceListComponent from "./deviceListComponent";
import { useMediaQuery } from 'react-responsive'
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  mainStruct: {
    maxWidth: 1440,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 0,
    marginBottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginRight: 0,

    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  mainStructWrapper: {
    display: "flex",
    width: "100%",
    backgroundColor: "#fff",
    position: "relative",
    justifyContent: "space-around",
    alignItems: "center",
    height: 70,
    marginBottom: 10,
    marginTop: 2,
  },
  logo:{
    // position: "absolute",
    // left: 50,
    // top: 10,
    width: 122,
    height: 49,
    [theme.breakpoints.down("xs")]: {
      // width: '50%',
      position: 'absolute',
      display: 'none',
      flexWrap: 'wrap',
      flexDirection: 'column'

    },
    [theme.breakpoints.down("sm")]: {
      position: 'absolute',
      display: 'none',
      flexWrap: 'wrap',
      flexDirection: 'column'
    },
  },
  menuButton: {
    display: 'none',
    width: 40,
    height: 40,
    [theme.breakpoints.down("xs")]: {
      display: 'block',

    },
    [theme.breakpoints.down("sm")]: {
      display: 'block',
    },
  },

  wrapGraphAndDL: {
    display: "flex",
  },

  title: {
    color: "#0F34BB",
    fontFamily: "Roboto",
    fontSize: 28,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      // width: '50%',
      position: 'absolute',
      display: 'none',
      flexWrap: 'wrap',
      flexDirection: 'column'

    },
    [theme.breakpoints.down("sm")]: {
      position: 'absolute',
      display: 'none',
      flexWrap: 'wrap',
      flexDirection: 'column'
    },
  },
  breakPointMenu: {
    display: 'none',
    [theme.breakpoints.down("xs")]: {
      width: '100%',
      left: 0,
      zIndex: 1000,
      position: 'absolute',
      display: 'none',
      flexWrap: 'wrap',
      flexDirection: 'column'

    },
    [theme.breakpoints.down("sm")]: {
      zIndex: 1000,
      position: 'absolute',
      display: 'none',
      flexWrap: 'wrap',
      flexDirection: 'column'
    },
   },

  titleMobile: {
    display: 'none',
    [theme.breakpoints.down("xs")]: {
      // width: '50%',
      display: 'block',
      color: "#0F34BB",
      fontFamily: "Roboto",
      fontSize: 18,
      textTransform: "uppercase",
      fontWeight: "bold",


    },
    [theme.breakpoints.down("sm")]: {
      display: 'block',
      color: "#0F34BB",
      fontFamily: "Roboto",
      fontSize: 18,
      textTransform: "uppercase",
      fontWeight: "bold",
    },
  },
  menuWrapper: {
    display: "flex",
    justifyContent: "space-around",
    width: '100%',

    [theme.breakpoints.down("xs")]: {
      // width: '50%',
      position: 'absolute',
      display: 'none',
      flexWrap: 'wrap',
      flexDirection: 'column',
      top: 0,
    },
    [theme.breakpoints.down("sm")]: {
      position: 'absolute',
      display: 'none',
      flexWrap: 'wrap',
      flexDirection: 'column',
      top: 0,
    },
  },

  menuDividerList: {

  },
  breackPointWrap: {
    // [theme.breakpoints.down("xs")]: {
    //   display: 'flex',
    //   justifyContent: 'center'

    // },
    // [theme.breakpoints.down("sm")]: {
    //   display: 'flex',
    //   justifyContent: 'center'
    // },
  },

  paperGraph: {
    width: "85.5%",
    display: "flex",
    alignItems: "flex-end",
    marginTop: 10,
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100%",


    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  navGraphWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  dotsWrap: {
    display: "flex",
    justifyContent: "space-between",
    width: 40,
    alignItems: "center",
  },
  pagesWrap: {
    cursor: 'pointer',
    width: "100%",
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center",
    marginTop: 10,
  },
  footerWrap: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    marginBottom: 10,
  },
  filterBtnsWrap: {
    width: "85.5%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,

    [theme.breakpoints.down("xs")]: {
      flexWrap: 'wrap',
      width: "100%",


    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dateBtn: {
    backgroundColor: "#00C99B",
    borderRadius: 5,
    textAlign: 'center',
    width: "100%",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    textTransform: "none",
        [theme.breakpoints.down("xs")]: {
      flexWrap: 'wrap',
      width: "100%",
      minHeight: 50,
      maxHeight: 50,

    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: 50,
      maxHeight: 50,
    },
  }

}));
function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

let interval_id = 0;

const MainPage = (props) => {
  const dispatch = useDispatch();
  const currentDevice = useSelector(
    (state) => state.deviceReducer.currentDevice
  );
  const devicesMultilineList = useSelector(
    (state) => state.deviceReducer.devicesMultilineList
  );
  const [dotPag, setDotPag] = React.useState(0);
  const multilineMode = useSelector(
    (state) => state.deviceReducer.multilineMode
  );
  const isLoad = useSelector((state) => state.deviceReducer.isLoad);

  const devicesList = useCallback(() => dispatch(actions.getDevicesList()), [
    dispatch,
  ]);
  const isMobile = useMediaQuery({ query: '(max-width: 954px)' })
  const setIsLoad = useCallback(
    (isLoad) => dispatch(actions.setIsLoad(isLoad)),
    [dispatch]
  );

  const setCurrentDeviceArr = useCallback(
    (currDeviceArr) => dispatch(actions.setCurrentDeviceArr(currDeviceArr)),
    [dispatch]
  );
  const fullList = useSelector((state) => state.deviceReducer.fullList);
  const setRange = useCallback((range) => dispatch(actions.setRange(range)), [
    dispatch,
  ]);
  const [page, setPage] = React.useState(1);
  const [dotsLength, setDotsLength] = React.useState([]);
  const [L, setL] = React.useState(false);
  const [ML, setML] = React.useState(false);
  const [DL, setDL] = React.useState(false);
  const [gLint, setGLint] = React.useState(false);
  const [multiclickCount, setMulticlickCount] = React.useState(0);
  const [del, setDel] = React.useState(0);
  const [multiclickCountRight, setMulticlickCountRight] = React.useState(0);
  const [multiclickCountLeft, setMulticlickCountLeft] = React.useState(0);
  const deviceList = useSelector((state) => state.deviceReducer.deviceList);
  const deviceInfoArr = useSelector(
    (state) => state.deviceReducer.currDeviceArr
  );
  const maxSize = useSelector((state) => state.deviceReducer.currMaxSize);
  const currentPeriod = useSelector(
    (state) => state.deviceReducer.currentPeriod
  );
  const range = useSelector((state) => state.deviceReducer.range);


    useEffect(()=> {

      if(!isMobile) {
        setDL(false);
        setML(false);
      }



    }, [isMobile])

  const isSelPer = useSelector((state) => state.deviceReducer.is_selected_period_sample);
 useEffect(()=> {
   //debugger
   if(isSelPer){
     return;
   }
  let x =Math.round(fullList.length/100);
  if(x%2!==0) {
    x = x+1;
  }
  setDel(x);
  if(fullList?.length){
    // if(fullList.length<=100){
    //   setDotPag(0)
    //   setDotsLength([1]);
    //   // setRange([range[0],range[0]+a[a.length-1]]);
    //   // setDotPag(a.length-1)
    //   // setCurrentDeviceArr(fullList.slice(range[0], range[0]+a[a.length-1]));
    //   // // setDotPag(a.length-1);
    //   // setDotsLength(a);
    //   return;
    // }
    let a= [];

    for(let i =fullList.length; i>=10; i=(i/2).toFixed() ){
      a.push(Number(i));
    }
    a = a;

    setRange([range[0],range[0]+a[0]]);
    setDotPag(0)
    // setCurrentDeviceArr(fullList.slice(range[0], range[0]+a[a.length-1]));
    setCurrentDeviceArr(fullList);
    // setDotPag(a.length-1);
    setDotsLength(a);
  }

 }, [fullList, ])



  const timerRefRight = React.useRef();
  const timerRefLeft = React.useRef();

  const cond = useCallback(() => {

    let x = (fullList.length/(del*2)).toFixed()
    let i =dotsLength.findIndex((item)=>item===Number((fullList.length/(del+2)).toFixed()))
  }, [fullList, del, dotsLength])


  const rLim = useCallback(()=> {


    // if (maxSize <= 100) {
    //   return;
    // }

    setIsLoad(true);
    // if(del===0) {

    //   setDel(del+2);

    //   setRange([range[0],range[0]+Math.round(fullList.length/(del+2))]);
    //   setDotPag(dotsLength.findIndex((item)=>item===(fullList.length/(del+2)).toFixed()) ===-1 ? dotPag : dotsLength.findIndex((item)=>item===(fullList.length/(del+2)).toFixed()))
    //   setCurrentDeviceArr(fullList.slice(range[0], range[0]+Math.round(fullList.length/(del+2))));
    //   return;
    // }


    setIsLoad(true);

    // this pravka
    // if(deviceInfoArr.length === fullList.length){
    //   setDel(6);
    //   setRange([range[0], Math.round(fullList.length/6)]);
    //   setCurrentDeviceArr(fullList.slice(range[0], Math.round(fullList.length/6)));
    //   return
    // }
    // if(del>0){
    //   if((fullList.length/(del*2)).toFixed()<=1){
    //     setDotPag(dotsLength.length-1)
    //     setDel(0);
    //     setRange([range[0],fullList.length]);
    //     setCurrentDeviceArr(fullList);
    //     return;
    //   }
    //   setDel(del*2);
    //   cond()
      // setDotPag(dotsLength.findIndex((item)=>item===Number((fullList.length/(del*2)).toFixed())) ===-1 ? dotPag : dotsLength.findIndex((item)=>item===Number((fullList.length/(del*2)).toFixed())))



        if(dotPag!==(dotsLength.length-1)){
          setRange([range[0],range[0]+dotsLength[dotPag+1]]);
          setDotPag(dotPag+1);
          setCurrentDeviceArr(fullList.slice(range[0], range[0]+dotsLength[dotPag+1]));
          }
      // setDotPag(a.length-1);
      // setDotsLength(a);
      // setRange([range[0],range[0]+Math.round(fullList.length/(del*2))]);
      // setCurrentDeviceArr(fullList.slice(range[0], range[0]+Math.round(fullList.length/(del*2))));

  }, [del, fullList, range, deviceInfoArr,])



  useEffect(() => {
    if(multiclickCountRight>0){
      // setIsLoad(true);
    }
    if (timerRefRight.current) {
      clearInterval(timerRefRight.current);
    }

    timerRefRight.current = setTimeout(() => {

      if (multiclickCountRight === 0) {
        return;
      }
      if (maxSize <= 100) {
        return;
      }

      // if (multiclickCountRight > 1 && multiclickCountRight <= 6) {
      //   setL(true)
      //   setIsLoad(true);
      //   setCurrentDeviceArr(
      //     fullList.slice(range[0], range[0] + 100 * multiclickCountRight)
      //   );
      //   setMulticlickCountRight(0);
      //   setL(false)
      //   return;
      // } else {
      //   setMulticlickCountRight(0);
      // }
      // if (maxSize <= 100) {
      //   return;
      // }
      // if (multilineMode&&maxSize <= 50) {
      //   return;
      // }
      setIsLoad(true);

      // this pravka
      if(deviceInfoArr.length === fullList.length){
        setDel(2);
        setRange([range[0], Math.round(fullList.length/2)]);
        setCurrentDeviceArr(fullList.slice(range[0], Math.round(fullList.length/2)));
      }
      if(del>0){
        setDel(del+2);
        setRange([range[0],range[0]+Math.round(fullList.length/(del+2))]);
        setCurrentDeviceArr(fullList.slice(range[0], range[0]+Math.round(fullList.length/(del+2))));
      }

      // if (range[0] + 100 >= maxSize) {
      //   setRange([0, 100]);
      //   setCurrentDeviceArr(fullList.slice(0, 100));
      //   setPage(1);
      //   return;
      // }
      // dotPag + 1 <= 5 ? setDotPag(dotPag + 1) : setDotPag(1);
      // setPage(page + 1);
      // setRange([range[0] + 100, range[1] + 100]);
      // setCurrentDeviceArr(fullList.slice(range[0] + 100, range[1] + 100));
    }, 100);
  }, [multiclickCountRight]);

  const rp = useCallback(()=>{

    // if(del===0){
    //   return;
    // }
    // if( fullList.length<=100){
    //   return;
    // }
    let del = dotsLength[dotPag];
    setIsLoad(true);
    if (range[0] + del >= fullList.length) {
      // setRange([0, del]);
      setIsLoad(false);
      // setCurrentDeviceArr(fullList.slice(0, del));
      // setPage(1);
      return;
    }
    setRange([range[0] + del, range[1] + del]);
    setCurrentDeviceArr(fullList.slice(range[0] + del, range[1] + del));
    // setPage(page+1);
  },[fullList, range, deviceInfoArr, dotsLength, dotPag])

  useEffect(() => {
    if (timerRefLeft.current) {
      clearInterval(timerRefLeft.current);
    }

    timerRefLeft.current = setTimeout(() => {
      if (multiclickCountLeft === 0) {
        return;
      }
      if (maxSize <= 100) {
        return;
      }
      setIsLoad(true);
      // if (multiclickCountLeft > 1 && multiclickCountLeft <= 6) {
      //   setIsLoad(true);
      //   setCurrentDeviceArr(
      //     fullList.slice(
      //       range[0],
      //       deviceInfoArr.length / 100 <= multiclickCountLeft
      //         ? range[1]
      //         : range[0] +
      //             100 * (deviceInfoArr.length / 100 - multiclickCountLeft)
      //     )
      //   );
      //   setMulticlickCountLeft(0);
      //   return;
      // } else {
      //   setMulticlickCountLeft(0);
      // }
      // setIsLoad(true);
      // if (range[0] - 100 < 0) {
      //   if (maxSize % 100 === 0) {
      //     dotPag - 1 <= 0 ? setDotPag(5) : setDotPag(dotPag - 1);
      //     setPage(
      //       maxSize % 100 > 0
      //         ? Number((maxSize / 100 + 1).toFixed())
      //         : Number((maxSize / 100).toFixed())
      //     );
      //     setRange([maxSize - 100, maxSize]);
      //     setCurrentDeviceArr(fullList.slice(maxSize - 100, maxSize));
      //     return;
      //   }
      //   setRange([maxSize - (maxSize % 100), maxSize]);
      //   setCurrentDeviceArr(fullList.slice(maxSize - (maxSize % 100), maxSize));
      //   setPage(
      //     maxSize % 100 > 0
      //       ? Number((maxSize / 100 + 1).toFixed())
      //       : Number((maxSize / 100).toFixed())
      //   );
      //   return;
      // }
      // dotPag - 1 <= 0 ? setDotPag(5) : setDotPag(dotPag - 1);
      // setPage(page - 1);

      if(deviceInfoArr.length === fullList.length){
        return;
      }
      setRange([range[0] - 100, range[1] - 100]);
      setCurrentDeviceArr(fullList.slice(range[0] - 100, range[1] - 100));
    }, 500);
  }, [multiclickCountLeft]);

  const lLim = useCallback(()=> {


    // if (maxSize <= 100) {
    //   return;
    // }



    setIsLoad(true);
    // if(del===0) {
    //   return;
    // }
    // if(deviceInfoArr.length === fullList.length){
    //   return;
    // }

    // if(del>0){
    //   if(((fullList.length/(del/2)).toFixed()<=1)){
    //     setDel(0);
    //     setRange([range[0],fullList.length]);
    //     setCurrentDeviceArr(fullList);
    //     return;
    //   }
    //   setDel(del/2);
    //   if(del/2===0){
    //     setRange([0,fullList.length]);
    //     setCurrentDeviceArr(fullList);
    //     return;
    //   }
    //   setRange([range[0],range[0]+Math.round(fullList.length/(del/2))]);
    //   setCurrentDeviceArr(fullList.slice(range[0],range[0]+Math.round(fullList.length/(del/2))));
    // }

    // this pravka
      if(dotPag!==0){
        setRange([range[0],range[0]+dotsLength[dotPag-1]]);
        setDotPag(dotPag-1);
        setCurrentDeviceArr(fullList.slice(range[0], range[0]+dotsLength[dotPag-1]));
        }


  }, [del, fullList, range, deviceInfoArr])

  const lp = useCallback(()=>{

    if(deviceInfoArr.length === fullList.length){
      return;
    }
    // if( fullList.length<=100){
    //   return;
    // }

    // if(del===0){
    //   return;
    // }
    setIsLoad(true);
    let del = dotsLength[dotPag];
    if (range[0] - del < 0) {
      setIsLoad(false);
      // if (fullList.length % (del) === 0) {
      //   // dotPag - 1 <= 0 ? setDotPag(5) : setDotPag(dotPag - 1);
      //   // (fullList.length / del).toFixed()
      //   // setPage(
      //   //   fullList.length % del > 0
      //   //     ? Number((maxSize / (fullList.length/del)).toFixed())
      //   //     : Number((maxSize / (fullList.length/del)).toFixed())
      //   // );
      //   setRange([fullList.length - del, fullList.length]);
      //   setCurrentDeviceArr(fullList.slice(fullList.length - del, fullList.length));
      //   return;
      // }
      // setRange([fullList.length - (maxSize % del), fullList.length]);
      // setCurrentDeviceArr(fullList.slice(fullList.length - (fullList.length % del), fullList.length));


      // setPage(
      //   maxSize % del > 0
      //     ? Number((maxSize / (fullList.length/del)).toFixed())
      //     : Number((maxSize / (fullList.length/del)).toFixed())
      // );
      return;
    }
    // setPage(page - 1);
    setRange([range[0] - del, range[1] - del]);
    setCurrentDeviceArr(fullList.slice(range[0] - del, range[1] - del));
  },[ fullList, range, deviceInfoArr, dotsLength,dotPag ])

  useEffect(() => {
    // devicesList();

    setTimeout(function run() {
      devicesList();
      setTimeout(run, 5000);
    }, 1);
  }, [devicesList]);
function upd_arr_chart(){

  if (currentDevice&&currentPeriod) {
    const MomentDateTo = moment(currentPeriod[0] , 'YYYY-MM-DD hh:mm:ss a');
    const MomentDateFrom = moment(   currentPeriod[1] , 'YYYY-MM-DD hh:mm:ss a');
    let dateMod = !currentPeriod.length ?`&dt_1=${moment().toISOString()}&dt_2=${moment().subtract(7, "d").toISOString()}`
    : `&dt_1=${MomentDateTo.toISOString()}&dt_2=${MomentDateFrom.toISOString()}`;

    dispatch(actions.getDeviceInfo(currentDevice.device_key, dateMod))
  }
}



  useEffect(() => {

    // if(currentDevice) {
    //   setTimeout(function run() {
    //     dispatch(actions.getDeviceInfo(currentDevice.device_key));
    //     setTimeout(run, 60000);
    //   }, 1);
    // }

    setPage(1);
  }, [currentDevice]);

  useEffect(() => {
    clearInterval(interval_id);
    interval_id = setInterval(()=>upd_arr_chart(), 300000);
  }, [currentDevice, currentPeriod]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    window.onunload = function() {
      cookie_js.set(
        {
          user_key: "",
          pass_key: "",
          login_key: "",

        },
        {
          domain: ".fluidsens.embedded.biz.ua",
        }
      );
      // cookie_js.remove('user_key');
      // cookie_js.empty();
      window.location.href = "https://fluidsens.embedded.biz.ua/";
    };
  }, []);

  return (
    <LoadingOverlay active={L||isLoad} spinner text="Loading your content...">
      <main className={classes.mainStruct}>
        <div
          className={classes.mainStructWrapper}
        >
          <img
            src={logo}
            alt=""
            className={classes.logo}
          />
          <Typography
            className={classes.title}
          >
            Fluidsens International Inc.
          </Typography>
          <Typography
            onClick={()=>setDL(!DL)}
            className={classes.titleMobile}
          >
            Device list
          </Typography>

          <div
          className="asdasd"
          style={DL ?
            {display: 'block', position:'absolute',  top: 70, backgroundColor: '#f2f2f2', zIndex: 10}
            : {display: 'none'}}>
              <DeviceListComponent
                  handleClose={null}
                  deviceList={deviceList ? deviceList : []}
                  openLint={true}
                />
          </div>
          <div style={{
           // position: 'relative'
            }}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={()=>setML(!ML)}
          >
            <MenuIcon />
          </IconButton>
          <div
          className={classes.breakPointMenu}
          style={ ML ? { display: 'flex'} : {display: 'none'}}
        >

          {/* <MenuItemComponent
            name={"Device List"}
            variantPopper={"DEVICE_LIST"}
            deviceList={deviceList}
          /> */}

          <MenuItemComponent
            name={"Start Device"}
            variantPopper={"START_DEVICE"}
          />
          <MenuItemComponent
            name={"Stop Device"}
            variantPopper={"STOP_DEVICE"}
          />
          <MenuItemComponent
            name={"Add Device Graph"}
            variantPopper={"ADD_DEVICE_GRAPH"}
          />
          <MenuItemComponent
            name={"Device Setting"}
            variantPopper={"DEVICE_SETTING"}
          />
          <MenuItemComponent
            name={"Rename Device"}
            variantPopper={"RENAME_DEVICE"}
          />
          <MenuItemComponent
            name={"Your Account"}
            variantPopper={"YOUR_ACCOUNT"}
          />

        </div>
          </div>
        </div>
       <div className={classes.breackPointWrap}>
        <div
          className={classes.menuWrapper}
          // style={ ML ? { display: 'flex'} : {display: 'none'}}
        >

          <MenuItemComponent
            name={"Device List"}
            variantPopper={"DEVICE_LIST"}
            deviceList={deviceList}
          />

          <MenuItemComponent
            name={"Start Device"}
            variantPopper={"START_DEVICE"}
          />
          <MenuItemComponent
            name={"Stop Device"}
            variantPopper={"STOP_DEVICE"}
          />
          <MenuItemComponent
            name={"Add Device Graph"}
            variantPopper={"ADD_DEVICE_GRAPH"}
          />
          <MenuItemComponent
            name={"Device Setting"}
            variantPopper={"DEVICE_SETTING"}
          />
          <MenuItemComponent
            name={"Rename Device"}
            variantPopper={"RENAME_DEVICE"}
          />
          <MenuItemComponent
            name={"Your Account"}
            variantPopper={"YOUR_ACCOUNT"}
          />

        </div>


        </div>
        {/* <div className={classes.wrapGraphAndDL}> */}
        {/* <div> */}
        {/* <MenuItemComponent
            name={"Device List"}
            variantPopper={"DL"}
            deviceList={deviceList}
          /> */}
        {/* </div> */}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "flex-end", width:'100%', height: '100%' }}>
            <Paper
              className={classes.paperGraph}
            >
              <div
                className={classes.navGraphWrap}
              >
                <Button
                disabled={dotPag===0||!dotsLength.length?true: false}
                  onClick={() => {
                    // if (multiclickCountLeft <= 6) {
                    //   setMulticlickCountLeft(multiclickCountLeft + 1);
                    // }
                    lLim()
                    // leftPaginate();
                  }}
                >
                  <img src={leftPag} alt="" width={16} height={13} style={dotPag===0||!dotsLength.length?{opacity: '0.5'}:{}}/>
                </Button>
                <div
                  className={classes.dotsWrap}
                >
                  {
                    dotsLength?.length ? dotsLength.map((item, index)=>(
                      <span
                      key={`${item}-asd-${index}`}
                      style={
                        dotPag === index
                          ? {
                              width: 6,
                              height: 6,
                              borderRadius: 50,
                              backgroundColor: "#0F34BB",
                            }
                          : {
                              width: 4,
                              height: 4,
                              borderRadius: 50,
                              backgroundColor: "#EDEDF5",
                            }
                      }
                    ></span>
                    )) : null
                  }

                </div>
                <Button
                  disabled={Number(dotPag)===(dotsLength.length-1)||!dotsLength.length?true: false}
                  // onDoubleClick={currentDevice ? () =>{if(multiclickCount===0){setMulticlickCount(multiclickCount+2)}} : console.log()}
                  onClick={() => {
                    // if (
                    //   multiclickCountRight < 6 &&
                    //   deviceInfoArr.length <= 600
                    //   // && multiclickCount > 1
                    // ) {
                    //   setMulticlickCountRight(multiclickCountRight + 1);
                    // }
                    rLim()
                    // rightPaginate(multiclickCount);
                  }}
                >
                  <img src={rightPag} alt="" width={16} height={13} style={Number(dotPag)===(dotsLength.length-1)||!dotsLength.length?{opacity: '0.5'}:{}}/>
                </Button>
              </div>

              <div
                className={classes.pagesWrap}
              >
                {/* {currentDevice || devicesMultilineList.length > 0 ? (
                  <div>
                  <Typography>
                   Pages: { del===0 ? 1 : page}/
                    {del===0?1
                      : maxSize % (fullList.length/del) > 0
                      ? (maxSize / (fullList.length/del)).toFixed()
                      : maxSize / (fullList.length/del) <=1
                      ? 1
                      : (maxSize / (fullList.length/del)).toFixed()}
                  </Typography>
                  <Typography>
                      Limit: {del===0 ? fullList.length : (fullList.length/del).toFixed()}
                  </Typography>

                  </div>
                ) : null} */}


    <Typography variant='h6'  style={gLint ? { color: "red" } :{ color: "red" }} onClick={()=>setGLint(!gLint)}>?</Typography>
    {gLint ? <Typography style={  { color: "red" }}>To move the graph on screen, please click. <p style={{margin:0}}>To expand the graph do the same of the left arrow</p> </Typography> : null}
    {!fullList.length ? <Typography style={  { color: "red" }}>No values ​​for this period
 </Typography> : null}

              </div>

              <LineChartComponent
              data={deviceInfoArr ? deviceInfoArr : null}
              rp={rp}
              lp={lp}
              rpDisable={range[0] + dotsLength[dotPag] >= dotsLength[0]}
              lpDisable={range[0] - dotsLength[dotPag] < 0}/>
            </Paper>
          </div>
          </div>
          {/* </div> */}
          <div
            className={classes.footerWrap}
          >
            <div
              className={classes.filterBtnsWrap}
            >
              <Typography
                className={classes.dateBtn}
              >
                {fullList?.length
                  ? moment(currentPeriod[1])
                      .format("ll")
                      .replace(/ /g, "/")
                      .replace(/[,]/g, "") +
                    "  -  " +
                    moment(currentPeriod[0])
                      .format("ll")
                      .replace(/ /g, "/")
                      .replace(/[,]/g, "")
                  : " — "}
              </Typography>
              <MenuItemComponent
                name={"All Results"}
                variantPopper={"ALL_RESULTS"}
              />
              <MenuItemComponent
                name={"ALERT Results"}
                variantPopper={"ALERT_RESULTS"}
              />
              <MenuItemComponent
                name={"Normal Results"}
                variantPopper={"NORMAL_RESULTS"}
              />
              <MenuItemComponent
                name={"Selected Period"}
                variantPopper={"SELECTED_RESULTS"}
              />
            </div>
            <TableComponent data={deviceInfoArr ? deviceInfoArr : null} />
          </div>
      </main>
    </LoadingOverlay>
  );
};

export default MainPage;
