import React, {useState} from "react";
import {ClickAwayListener, Hidden, Paper, Typography} from "@material-ui/core";


import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../redux/actions/deviceActions/device.action";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    paper: {
        marginRight: theme.spacing(2),
    },

    block: {
        width: "100%",
        marginTop: 5,
        backgroundColor: "#fff",
        paddingTop: 10,
        paddingBottom: 10,
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
    },

}));

const LabelComponent = (props) => (
    <label
        style={{
            display: "flex",
            alignItems: 'center',
            marginTop: 1,
        }}
    >
        <span
            style={{
                fontSize: 14,
                fontFamily: "Roboto",
                fontWeight: 500,
                marginRight: 10
            }}
        >
            {props.labelName}
            <span
                style={{
                    fontSize: 14,
                    fontWeight: 900,
                    color: props.color,
                }}
            >
                {props.labelValue}
            </span>
        </span>

    </label>
);

const CalibResultComponent = (props) => {

    const currentDevice = useSelector(state => {
        const currentDeviceKey = state.deviceReducer.currentDevice?.device_key;
        //console.log(currentDeviceKey);
        if (currentDeviceKey) {
            const currentDeviceIndex =
                state.deviceReducer.deviceList?.findIndex(dev => dev.device_key === currentDeviceKey);
            //console.log(currentDeviceIndex);
            if ((currentDeviceIndex!= null) && (currentDeviceIndex >= 0)) {
                return state.deviceReducer.deviceList[currentDeviceIndex];
            }
        }
        return null;
    });

    if(currentDevice != null){
    }

    let calibTransimpedans = 0;
    let calibUVSens = 0;
    let calibLastTransimpedans = 0;
    let calibLastUVSens = 0;
    let calibLastStatus = 0;

    if(currentDevice != null) {
        calibTransimpedans = currentDevice.sentens.calib_trans;
        calibUVSens = currentDevice.sentens.calib_uvsens;
        calibLastTransimpedans = currentDevice.sentens.calib_last_trans;
        calibLastUVSens = currentDevice.sentens.calib_last_uvsens;
        calibLastStatus = currentDevice.sentens.calib_last_status;
    }
    let calibLastStatusText = 'Fault';
    let statusColor = '#DD0000';

    if (calibLastStatus === 1) {
        calibLastStatusText = 'Success';
        statusColor = '#00BB00';
    } else if (calibLastStatus === 2) {
        calibLastStatusText = 'Pending';
        statusColor = '#0000BB';
    }

    const classes = useStyles();

    return (
        <>
            {
                (props.open && (currentDevice != null)) ?
                    <div
                        style={{
                            width: "192px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            zIndex: 6,
                        }}
                    >
                        <ClickAwayListener onClickAway={props.handleClose}>
                            <Paper className={classes.block}
                                   style={{display: "flex", boxShadow: '-3px 3px 10px 4px rgba(221, 221, 221, 1)',}}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 900,
                                            marginBottom: 5,
                                        }}
                                    >
                                        Calibration In Use
                                    </div>

                                    <LabelComponent
                                        labelName={'Transimpedans: '}
                                        labelValue={calibTransimpedans}
                                        color={'#000000'}/>
                                    <LabelComponent
                                        labelName={'UV Sensor: '}
                                        labelValue={calibUVSens}
                                        color={'#000000'}/>
                                    <div
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 900,
                                            marginTop: 15,
                                            marginBottom: 5,
                                        }}
                                    >
                                        Last Calibration
                                    </div>
                                    {(calibLastStatus !== 2) ?
                                        <>
                                            <LabelComponent
                                                labelName={'Transimpedans: '}
                                                labelValue={calibLastTransimpedans}
                                                color={'#000000'}/>
                                            <LabelComponent
                                                labelName={'UV Sensor: '}
                                                labelValue={calibLastUVSens}
                                                color={'#000000'}/>
                                        </>
                                        :
                                        <></>
                                    }
                                    <LabelComponent
                                        labelName={'Status: '}
                                        labelValue={calibLastStatusText}
                                        color={statusColor}/>

                                </div>
                            </Paper>
                        </ClickAwayListener>
                    </div>
                    :
                    <></>
            }
        </>
    )
}

export default CalibResultComponent
